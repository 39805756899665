@use "../variables";

#classrooms {
  .classes_item_container {
    width: 610px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

    &.inside {
      margin-bottom: 15px;
    }

    .fields,
    .subjects {
      padding: 10px;
    }

    .btn_wrapper {
      padding-left: 15px;
      padding-bottom: 15px;
    }

    label {
      display: block;
    }

    .inputfield {
      padding-left: 10px;
      border: none;
      font-size: 14px;
      width: 580px;
      height: 40px;
      border-radius: 2px;
      background-color: #f2f2f2;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
        inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .subject_items {
    margin-top: 10px;
    margin-bottom: 10px;
    .select_item {
      display: inline-block;
      .checkbox {
        width: auto;
        height: auto;
        border-radius: 2px;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient(
          to bottom,
          #ffffff,
          #f7f7f7 48%,
          #f0f0f0 51%,
          #f1f1f1
        );
        border: solid 1px rgba(0, 0, 0, 0.35);
        margin-right: 10px;
      }
      padding-right: 15px;
    }
  }

  .classes_list {
    margin-top: 36px;

    .classes_item {
      width: 610px;
      min-height: 40px;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      margin-bottom: 15px;
      line-height: 40px;
      position: relative;

      .title {
        color: variables.$reg_title_color;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        padding-right: 20px;
        padding-left: 9px;
      }

      .subjects {
        .text-item {
          padding-right: 0px;
        }
        .text-item:last-child .comma {
          display: none;
        }
      }

      .actions {
        display: inline-block;
        float: right;
        padding-right: 9px;
        position: absolute;
        z-index: 3;
        right: 0px;
        padding-left: 10px;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
