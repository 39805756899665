@use "../variables";

.material-upload-wrapper {
  // wrapper paddings
  padding: 25px 50px 50px 50px;
  display: flex;
  flex-wrap: wrap;

  .items {
    line-height: 1.5;
    .name {
      line-height: 54px;
      font-weight: bold;
      font-size: 14px;

      &.link {
        height: 54px;
        line-height: 23px;
        vertical-align: middle;

        .domain {
          font-size: 10px;
          color: rgba(37, 41, 43, 0.6);
          line-height: 10px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }

      .domain {
        display: block;
        line-height: 0px;
      }
    }

    a {
      cursor: pointer;
      color: #25292b;
    }
  }

  // rows with formated cols
  .icon {
    width: 57px;
    position: relative;
    vertical-align: middle;

    &.upload {
      opacity: 0.1;
    }

    svg {
      max-height: 50px;
      display: inline-block;
      vertical-align: middle;
    }

    .favicon {
      position: absolute;
      right: 13px;
      bottom: 1px;

      img {
        width: 16px;
      }
    }
  }
  .name {
    width: 320px;
    &.upload {
      opacity: 0.1;
    }
  }
  .date {
    width: 169px;
    &.upload {
      opacity: 0.1;
    }
  }
  .process {
    width: 140px;
    &.upload {
      opacity: 0;
    }
  }

  .delete {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #e3e3e3 !important;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    cursor: pointer;

    &.deletedialogOpen {
      display: none;
    }

    &:hover {
      background-color: #cc5959 !important;
    }

    img,
    svg {
      width: 13px;
    }
  }

  .add_ue_items {
    cursor: pointer;
    border-bottom: none;
    padding-bottom: 13px;
    a {
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      color: #31383c;
      .toggle {
        text-decoration: underline;
        padding-left: 10px;
        font-size: 14px;
        line-height: 1.5;
        color: rgba(49, 56, 60, 0.6);

        &:hover {
          color: variables.$freigeist_green;
        }
      }
    }
  }

  // actions container
  .actions {
    width: 348px;
    padding-left: 40px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #25292b;
      margin-bottom: 12px;
    }

    .uploadbox {
      margin-bottom: 20px;
      .box {
        height: 140px;
        //border-radius: 5px;
        //border: dashed 2px #E0E0E0;
        position: relative;

        .innerBox {
          &:hover {
            border-color: #4bc88c !important;
            color: #4bc88c !important;
          }

          p {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            text-align: center;
            text-decoration: underline;
            margin: 0;
          }
        }
      }
    }
  }
}

// material wrapper im ue context
#articluation_wrapper {
  .material-upload-wrapper {
    padding-left: 20px;
    padding-right: 20px;

    .item-list {
      width: 709px;
    }

    .actions {
      width: 338px;
      padding-left: 25px;
    }
  }
}
