@use "../variables";

#notification {
  .text-wrapper {
    padding-top: 30px;
  }

  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.4;
  color: #3a464c;
  min-height: auto;

  .label {
    display: inline-block;
    border-radius: 3px;
    color: #fff;
    background-color: variables.$color-green;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 10px;
  }
  .headline {
    padding-top: 10px;
    display: block;
    padding-bottom: 0px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  }

  .link-wrapper {
    display: block;
    padding-top: 10px;
    a {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.57;
      letter-spacing: normal;
      color: #2a3134;
      text-decoration: underline;
    }
  }
}
