@use "edit";

// common styles
#pdf-dashboard {
  .row-wrapper {
    width: 613px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 70px;

    &.list {
      .block {
        margin-bottom: 20px;
      }
    }

    .block {
      width: 100%;
      border-radius: 2px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      padding: 15px;

      &.blank {
        background-color: transparent;
        box-shadow: none;
      }

      .title {
        font-weight: bold;
      }

      .label {
        padding-bottom: 5px;
      }

      .dropdown-wrapper {
        width: 100%;
        .left {
          float: left;
          width: 50%;
        }
        .right {
          float: left;
          width: 50%;
        }
      }

      .dropdown {
        width: 284px;
      }
    }
  }
}
#settings-dashboard {
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 28px;
  }

  .setting-row-wrapper {
    width: 613px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 70px;

    .description {
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 20px;
    }

    #classrooms .classes_list {
      margin-top: 15px;
    }
    #subjects .subject_list {
      margin-top: 15px;
    }

    .btn-wrapper {
      &.steps-btns {
        display: none;
      }
    }

    &.timetable {
      width: 820px;

      .head-wrapper {
        width: 613px;
        margin-right: auto;
        margin-left: auto;
      }

      #timetableView {
        width: 700px;
        margin-right: auto;
        margin-left: auto;

        &.with_saturday {
          width: 820px;
        }
      }
    }

    #password_form {
      margin-top: 0px;
    }

    #school {
      .policy-data,
      #student {
        display: none;
      }
    }
  }

  .action_form_wrapper {
    width: 610px;
    min-height: 40px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

    .input_radio {
      width: auto;
      height: inherit;
      margin-right: 9px;
    }

    .radio-inline {
      padding-right: 20px;
    }

    .edit {
      padding-left: 14px;
      padding-top: 20px;
    }

    .inputfield {
      padding-left: 10px;
      border: none;
      font-size: 14px;
      width: 570px;
      height: 40px;
      border-radius: 2px;
      background-color: #f2f2f2;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
        inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
    }

    .btn-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .readonly {
      line-height: 40px;
      .label {
        padding-left: 14px;
        font-weight: bold;
        padding-right: 40px;
      }
      .actions {
        padding-right: 14px;
      }
    }
  }

  #region-schooltype
  {
    .description
    {
      padding-bottom: 20px;
    }
  }
}
