#ab-weeks-grid {
  // grid on yearly overview

  .childs-container {
    width: 100%;
    height: auto;

    .childs-row {
      min-height: 21px;
      display: inline-block;
      text-align: center;
      font-size: 10px;
      position: relative;

      &.active {
        height: 200px;
      }

      // inside grid dialog
      .inner-wrapper {
        a {
          cursor: pointer;
          display: inline-block;
          width: 100%;

          &:hover {
            display: inline-block;
            width: 100%;
            border-radius: 3px;
            background-color: #ffffff;
          }

          &.A {
            color: #2a2e31;
          }

          &.B {
            color: #303538;
          }
        }
      }

      // ab dialog
      .dialog {
        text-align: left;
        display: none;
        position: absolute;
        width: 180px;
        height: 159px;
        //padding: 11.6px 11px 12px;
        white-space: initial;
        left: -60px;
        margin-top: 20px;
        top: 6px;

        font-size: 14px;
        line-height: 32px;

        .triangle-wrapper {
          background-color: transparent;
          padding-left: 29px;

          .triangle-top {
            position: relative;
            top: 0px;
            left: 56px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5px 7.5px 5px;
            border-color: transparent transparent #fff transparent;
          }
        }

        .dialog-wrapper {
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
          background-color: #ffffff;
          padding: 11px;

          .ab_changer {
            cursor: pointer;
            color: #4c5259;
            &.visible {
              color: #1ca0e2;
            }
          }

          .icon {
            padding-right: 6px;

            img {
              width: 10px;
              visibility: hidden;

              &.visible {
                visibility: visible;
              }
            }
          }

          .info {
            padding-top: 8px;
            padding-left: 17px;
            padding-bottom: 12px;
            width: 135px;
            height: 45px;
            font-size: 10px;
            line-height: 1.5;
            color: #4c5259;
          }
        }

        &.col-0 {
          left: auto;
          .triangle-top {
            left: -5px;
          }
        }

        &.holiday {
          width: 240px;
          left: -90px;

          .triangle-wrapper {
            padding-left: 60px;
          }
        }

        &.active {
          display: block;
        }
      }
    }
  }
}
