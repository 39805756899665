@use "../variables";

.paymentModal,
.commonModal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;

  &.firstTimeModal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .paymenModalContent,
  .commonModalContent {
    width: 610px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    position: relative;

    .textlink {
      color: variables.$color-black;
      text-decoration: underline;
    }

    .closer {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 99;
      cursor: pointer;
      img {
        width: 25px;
      }
    }

    .text-wrapper {
      padding: 40px 50px 55px;
      .intro-text {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 1.4;
      }
      .ref-text {
        margin-top: 30px;
        font-size: 14px;
        line-height: 1.57;
      }
      .btn-wrapper {
        padding-top: 15px;
        padding-bottom: 30px;
        .btn {
          font-family: variables.$font-family;
          font-size: 14px;
          font-weight: bold;
        }
        .abo_cancel_link {
          display: inline-block;
          padding-left: 30px;
          a {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.57;
            color: #2a3134;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.commonModal {
  top: 45%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  &.transparent {
    .commonModalContent {
      box-shadow: none;
      background-color: transparent;
    }
  }

}
.topModal {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  top: 50px;
  width: 610px;
  left: 0;
  right: 0;
  transform: none;
}

.ReactModal__Overlay {
  &.paymentOverlay,
  &.commonOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(64, 184, 126, 0.7) !important;
  }
}
.isblured {
  filter: blur(3px);
}

.first_time_note {
  .text-wrapper {
    padding-top: 43px !important;

    a.mailto {
      color: #3a464c;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .video-wrapper {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .btn {
    margin-top: 13px;
    width: 116px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #4bc88c;
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
}
