.cleanSelect {
  position: absolute;
  z-index: auto;
  width: 100%;

  .react-select__menu-notice,
  .react-select__menu-notice--no-options {
    display: none;
  }

  .react-select__option,
  .react-select__option--is-focused {
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }

  .react-select__menu-list,
  .react-select__menu-list--is-multi {
    padding-bottom: 4px;
    padding-top: 4px;
  }

  .react-select__value-container,
  .react-select__value-container--is-multi,
  .react-select__value-container--has-value {
    padding: 0px;
    padding-right: 10px;
  }

  .react-select__menu {
    margin-top: 0px;
    width: auto;
  }

  // blauer focus eingabefeld
  .react-select__control,
  .react-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  // tag grauer kasten
  .react-select__multi-value__label {
    padding: 2px;
    min-height: 25px;
    padding-left: 5px;
    white-space: normal;
  }

  .Select-arrow-zone {
    display: none;
  }

  .Select-menu-outer {
    z-index: 9999 !important;
  }

  .Select-menu-outer {
    display: none;
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__control {
    border: none;
    background-color: transparent;
  }
}
