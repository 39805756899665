@use "../variables";

.tab-main-wrapper {
  .tab-link {
    font-family: variables.$font-family;
    cursor: pointer;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ebebeb;
    border: none;
    margin-right: 5px;
    padding-left: 19px;
    padding-right: 19px;

    font-weight: 700;
    font-size: 14px;
    color: #2a3134;

    &.tab-link-active {
      background-color: #fff;
    }
  }

  .tabs-second-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    .tab-link {
      background-color: #e3e3e3;
      &.tab-link-active {
        background-color: #f2f2f2;
      }
    }
    .content {
      background-color: #f2f2f2 !important;
      padding: 20px;
      width: 100%;
      border-top-left-radius: 0px !important;
    }
  }

  .tab-content {
    background-color: #fff;
  }

  #tabpanel-notes,
  #tabpanel-notes-unit {
    padding: 0px 0px;
    font-size: 21px;
    line-height: 30px;

    .fgEditor {
      padding-top: 70px;
      padding-bottom: 70px;

      .public-DraftEditorPlaceholder-inner {
        font-size: 21px;
      }

      .__editor_wrapper {
        ul {
          list-style: initial;
          margin-left: 22px;
        }
        padding-top: 20px;
        padding-left: 160px;
        padding-right: 160px;
        &.edit_mode {
          color: variables.$editor_edit_color;
        }
        &.view_mode {
          color: variables.$editor_view_color;
          .public-DraftEditorPlaceholder-inner {
            display: none;
          }
        }
      }

      .editor-control {
        font-size: 14px;
        font-weight: bold;
        height: 59px;
        background-color: #fff;
        padding-left: 160px;
        padding-right: 160px;

        .left {
          float: left;
        }
        .right {
          float: right;
        }

        &.sticky {
          position: sticky;
          top: 0;
          left: 0;
          z-index: 1;
          border-bottom: 1px solid rgba(37, 41, 43, 0.25);
          .left,
          .right {
            padding-top: 12px;
          }
        }
      }
    }
  }
}
