@use "../variables";

#subjects {
  .subject_item_container {
    width: 610px;
    height: 145px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
    position: relative;

    .picker {
      position: absolute;
      left: 450px;
      width: 100%;
    }

    &.inside {
      margin-bottom: 20px;
    }

    .fields {
      padding: 15px;
    }

    .color_wrapper {
      position: relative;
      width: 111px;
      height: 40px;
      cursor: pointer;
      border-radius: 2px;
      //top: 20px;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
        inset 0 0 2px 0 rgba(0, 0, 0, 0.25);

      .twitter-picker {
        //  top: 45px;
      }
    }

    .btn_wrapper {
      padding-left: 15px;
    }

    label {
      display: block;
    }

    .file_color {
      width: 111px;
      float: left;
      padding-left: 15px;
    }
    .field_input {
      float: left;
    }

    .color_field {
      width: 111px;
    }

    .inputfield {
      padding-left: 10px;
      border: none;
      font-size: 14px;
      width: 400px;
      height: 40px;
      border-radius: 2px;
      background-color: #f2f2f2;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
        inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .subject_list {
    margin-top: 36px;

    .subject_item {
      min-height: 40px;
      margin-bottom: 16px;
      background-color: #fff;
      width: 610px;
      line-height: 40px;
      border-radius: 2px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

      .color {
        display: inline-block;
        padding-left: 9px;
        padding-right: 9px;
        position: relative;
        top: 7px;

        .circle {
          width: 26px;
          height: 26px;
          border-radius: 13px;
          box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
            inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
          display: inline-block;
        }
      }

      .title {
        color: variables.$reg_title_color;
        font-size: 14px;
      }

      .actions {
        display: inline-block;
        float: right;
        padding-right: 9px;
      }
    }
  }
}
