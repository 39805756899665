#school,
#region-schooltype {
  max-width: 610px;

  .head {
    padding-bottom: 10px;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 700;

    &.second {
      margin-top: 42px;
    }
  }

  .step-text {
    padding-bottom: 15px;
  }

  .dashboard-btns {
    padding-top: 15px;
  }


  .school-label {
    height: 22px;
    font-size: 14px;
    line-height: 1.57;
    color: #3a464c;
  }

  .school-input {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    background-color: #f2f2f2 !important; /* ugly hack because of conflicting register stylesheet */
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
      inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
    border: none;
    padding-left: 13px !important;
    color: #3a464c;
    font-size: 14px;

    &.checkbox {
      height: auto;
      width: auto;
    }
  }

  .form-wrapper {
    width: 610px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);

    .text {
      padding-top: 16px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      font-weight: 700;
    }

    .field-group {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 575px;

      &.dropdowns {
        width: 590px;
      }
      &.refs {
        width: 360px;
      }

      label {
        height: 22px;
        font-size: 14px;
        line-height: 1.57;
        color: #3a464c;
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 2px;
        background-color: #f2f2f2;
        box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
          inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
        border: none;
        padding-left: 13px;
        color: #3a464c;
        font-size: 14px;

        &.checkbox {
          height: auto;
          width: auto;
          margin: 3px 13px 3px 4px;
        }
      }

      .col-street {
        float: left;
        width: 465px;
      }
      .col-street-nr {
        float: left;
        width: 85px;
        margin-left: 25px;
      }

      .col-zip {
        float: left;
        width: 85px;
        margin-right: 25px;
      }

      .col-location {
        float: left;
        width: 465px;
      }

      .col-region,
      .col-schooltype {
        width: 289px;
      }

      .col-region {
        float: left;
        margin-right: 5px;
      }
      .col-schooltype {
        float: left;
        margin-left: 5px;
      }
    }
  }
}
.region-schooltype-btns {
  padding-top: 15px;
}
#region-schooltype,
#student {
  .step-text {
    margin-top: 0;
  }
  .head {
    padding-bottom: 0;
  }
}
