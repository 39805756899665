.editor-wrapper {
  position: relative;
}

:focus {
  outline: none;
}
::-moz-focus-inner {
  border: 0;
}
