$font-family: "Work Sans", sans-serif;

/* paddings & spaces */
$spacer: 20px;

/* colors */
$main-grey: #f2f2f2;
$color-black: #25292b;
$color-water-blue: #1ca0e2;
$color-dark-blue: #276fa5;
$color-dark-gray: #e3e3e3;
$color-green: #40b87e;
$light-grey: f2f2f2;

$freigeist_green: #4bc88c;
$error_red: #cc5959;
$edit_grey: #e3e3e3;

/* editor */
$editor_view_color: #6d7275;
$editor_edit_color: #2a3134;

/* nav */
$nav-font-size: 14px;
$nav-line-height: 17px;
$nav-font-weight: 700;
$nav-font-color: rgba(37, 41, 43, 0.6);

/* fonts */
$font-size: 14px;
$line-height: 21px;

/* font sizes registration process */
$reg-font-size: 20px;
$reg-line-height: 28px;
$reg-font-color: #3a464c;
$reg_title_color: #646769;

/* common spacers */
$spacer-innerbox-top: 14px;
