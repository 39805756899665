@use "../variables";

#registration {
  padding-bottom: 80px;

  h1 {
    margin-top: 48px;
    text-align: center;
    font-size: 20px;
    color: #40b87e;
    margin-bottom: 20px;
  }

  color: variables.$reg-font-color;

  /*
  &.content-wrapper {
    max-width: 630px;
  }*/

  .step,
  .progtrckr {
    max-width: 610px;
    margin-right: auto;
    margin-left: auto;
  }

  &.new_user {
    .step,
    .progtrckr {
      max-width: 630px;
    }
  }

  .progtrckr {
    text-align: center;

    li:first-child {
      text-align: left;
    }
    li:last-child {
      text-align: right;
    }

    li span {
      padding: 0;
      display: inline-block;
      max-width: 80px;
      //line-height: 20px;
    }
    li {
      //line-height: 20px;
      width: 125px;
      //display: inline-flex;
      line-height: 21px;
      vertical-align: top;

      &::before {
        bottom: 5px;
      }
    }
    li:first-child {
      left: 0;
      em {
        position: relative;
        left: -8px;
      }
    }
    li:first-child::before {
      left: 0;
    }
    li:last-child {
      em {
        position: relative;
        right: -8px;
      }
    }
    li:last-child::before {
      left: 0;
      float: right;
    }

    em {
      display: block;
      padding: 0;
      font-style: normal;
      padding-top: 6px;
    }
  }

  ol.progtrckr li::after {
    content: normal;
  }

  ol.progtrckr li.progtrckr-doing {
    color: #40b87e;
  }

  ol.progtrckr li.progtrckr-doing,
  ol.progtrckr li.progtrckr-todo,
  ol.progtrckr li.progtrckr-done {
    border-top: 2px solid #b4b9bb;
    border-bottom: none;
  }

  ol.progtrckr li.progtrckr-doing::before,
  ol.progtrckr li.progtrckr-todo::before,
  ol.progtrckr li.progtrckr-done::before {
    color: transparent;
    line-height: 0;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #3a464c;
  }

  .step-text {
    margin-top: 70px;
    font-size: variables.$reg-font-size;
    line-height: variables.$reg-line-height;
    .title {
      font-weight: bold;
    }
  }

  .steps-buttons {
    text-align: right;
    margin-top: 30px;
  }

  .btn-wrapper {
    margin-top: 31px;
    width: 100%;
    .back {
      a {
        color: #3a464c;
        text-decoration: underline;
      }
      float: left;
      width: 50%;
    }
    .next {
      text-align: right;
      float: right;
      width: 50%;
    }
  }
}
