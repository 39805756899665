@use "sass:list";
@use "../variables";

#password {
  &.password-change {
    max-width: 630px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;

    .headline,
    .description {
      margin-bottom: 30px;
    }

    .btn-dark {
      width: auto;
    }
  }

  #password_form {
    margin-top: 30px;

    .after-field-content {
      font-size: 12px;
      line-height: 16px;
      padding-top: 5px;
    }
  }

  label {
    font-size: variables.$font-size;
    font-weight: 400;
  }

  // strength password meter

  .strength-meter-wrapper {
    position: relative;
  }

  .strength-meter-text {
    position: absolute;
    z-index: 2;
    top: -3px;
    left: 320px;
    font-size: 12px;
    line-height: 16px;
  }

  /* Declare some variables */
  $primary: #007bff;

  // Password strength meter color for the different levels
  $strength-colors: (darkred, orangered, orange, yellowgreen, green);

  // Gap width between strength meter bars
  $strength-gap: 6px;

  .main-container {
    width: 400px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .form-container {
    bottom: 100px;
  }

  .strength-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
    width: 300px;
    top: 5px;

    // Dynamically create the gap effect
    &:before,
    &:after {
      content: "";
      height: inherit;
      background: transparent;
      display: block;
      border-color: #f2f2f2;
      border-style: solid;
      border-width: 0 $strength-gap 0;
      position: absolute;
      width: calc(20% - #{$strength-gap});
      z-index: 10;
    }

    // Dynamically create the gap effect
    &:before {
      left: calc(20% - #{($strength-gap * 0.5)});
    }

    // Dynamically create the gap effect
    &:after {
      right: calc(20% - #{($strength-gap * 0.5)});
    }
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;

    // Dynamically generate strength meter color styles
    @for $i from 1 through 5 {
      &[data-strength="#{$i - 1}"] {
        width: (20% * $i);
        background: list.nth($strength-colors, $i);
      }
    }
  }
}
