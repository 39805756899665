@use "../variables";

.template-modal,
.template-page {
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: none;
  }

  .sub-title {
    font-weight: bold;
    padding-top: 4px;
    .small {
      font-weight: normal;
    }
  }

  &.create {
    padding: 25px;
  }
  &.overflow {
    padding-right: 11px;
    background-color: #f2f2f2;
  }
  .btn_wrapper {
    .btn {
      margin-right: 10px;
    }
  }

  .field-group {
    padding-top: 20px;
    padding-left: 0px !important;
    padding-bottom: 0px !important;

    &.dropdowns {
      width: 100%;
      clear: both;
      .col {
        float: left;
        width: 49%;
      }
      .col-last {
        float: right;
        width: 49%;
      }
    }

    .tags-wrapper {
      width: 100%;
      min-height: 40px;
      border-radius: 2px;
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
        inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
      background-color: #f2f2f2;

      .react-tagsinput {
        overflow: hidden;
        background-color: transparent;
        border: none;
        padding-left: 14px;
        padding-top: 5px;

        .react-tagsinput-remove {
          color: #25292b;
          padding-left: 5px;
        }

        .react-tagsinput-tag {
          height: 25px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.1);
          border: none;
          color: #25292b;
          display: inline-block;
          font-family: variables.$font-family;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 5px;
          margin-right: 5px;

          padding-left: 10px;
          padding-right: 10px;
          padding-top: 4px;
          padding-bottom: 1px;
        }
      }
    }

    .footnote {
      padding-top: 6px;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
    }
  }
  .inputtext {
    width: calc(100% - 10px);
    height: 40px;
    border: none;
    padding-left: 10px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
      inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
    font-size: 14px;
    background-color: #f2f2f2;
  }
}

.template-wrapper,
.template-page {
  .tags-wrapper {
    padding-top: 20px;

    .title {
      color: #3a464c;
    }

    span {
      padding-right: 10px;
      height: 25px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.1);
      display: inline-block;
      margin-right: 5px;
      padding-left: 10px;
      padding-right: 10px;
      //padding-top: 5px;
      line-height: 25px;
      cursor: pointer;
      margin-bottom: 10px;

      &.active {
        background-color: #1ca0e2;
        color: #fff;
      }
      &.info-tag {
        background-color: #3a464c;
        color: #fff;
      }
      &.readonly {
        cursor: default;
      }
    }
  }

  .template-list-wrapper {
    margin-top: 30px;
    .item {
      width: 610px;
      min-height: 105px;
      border-radius: 3px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
      background-color: rgba(255, 255, 255, 0.8);
      margin-bottom: 14px;
      padding: 10px;

      &.hidden {
        display: none;
      }

      .item-wrapper {
        width: 100%;
        height: auto;
        .left {
          width: 510px;
          float: left;
          height: auto;
        }
        .right {
          float: left;
          width: 100px;
          height: auto;

          .action_item {
            padding-left: 5px;
          }
        }
      }

      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }

    &.use {
      .item {
        width: 100%;
        padding: 0px;
        min-height: auto;
        .item-wrapper {
          background-color: #1ca0e2;
          position: relative;

          .title a {
            cursor: pointer;
          }

          .left {
            display: inline-block;
            width: calc(100% - 55px);
            height: auto;
            float: none;
            background-color: #fff;
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 5px;
          }
          .right {
            display: inline-block;
            float: none;
            width: 55px;
            height: 100%;
            position: absolute;
            cursor: pointer;
          }
          .arrow {
            display: inline;
            position: absolute;
            top: 36%;
            color: #fff;
            font-size: 40px;
            right: 24px;
            font-family: arial;
            font-weight: 300;
          }
        }
      }
    }
    &.overflow {
      margin-top: 0;
      width: calc(100% - 10px);
      position: relative;
      right: -3px;
      top: 8px;
    }
  }

  .overflow-list {
    max-height: 400px;
    overflow-x: hidden;
    width: 100%;
    overflow-y: scroll;
  }
}
