@use "../variables";

.calendar-wrapper {
  margin-bottom: 290px;

  .controller {
    background-color: variables.$color-dark-gray;
    height: 55px;
  }
}

// layer empty subjects
.layer-note-empty-subject {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: auto;
  background-color: variables.$main-grey;
  overflow: hidden;
  font-size: 16px;
  padding-top: 10%;
  .content-layer {
    text-align: center;
    width: 400px;
    min-height: 200px;
    margin: auto;
    .setup-link {
      display: inline-block;
      width: auto;
      height: auto;
      padding: 8px 13px 10px 13px;
      background-color: #4bc88c;
      margin-left: auto;
      margin-right: auto;
      margin-top: 16px;
      border-radius: 5px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      color: #fff;
      font-size: 14px;
    }
  }
}

#sequence_calendar {
  .date-desc {
    position: relative;
    height: 25px;

    .item {
      position: absolute;
      top: 0px;
      width: auto;
    }
  }

  .content {
    width: 2000px;
  }

  .no-move {
    cursor: default;
  }

  .row {
    .cluster {
      .item {
        height: 132px;
        border-top: 1px solid #fff;
        background-color: cadetblue;
        color: #fff;
      }
    }

    .cal-grid {
      height: 100%;
      white-space: nowrap;
      position: relative;
      user-select: text;

      .items-grid {
        position: absolute;
        top: 0px;
        margin-top: 25px;

        .items {
          height: auto;
          user-select: none !important;

          .react-draggable {
            position: absolute !important;
            padding-bottom: 2px;
          }

          .resizer div {
            width: 25px !important;
          }
        }

        .seq_item {
          // mockup test
          height: 100%;
          //width: 98%;
          width: calc(100% - 5px);
          clear: both;
          position: relative;

          //overflow: hidden;

          border-radius: 3px;
          background-color: rgba(255, 255, 255, 0.8);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);

          border-top: solid 3px transparent;
          border-bottom: solid 3px transparent;

          margin-left: auto;
          margin-right: auto;

          font-weight: 700;
          font-size: variables.$font-size;
          color: variables.$color-black;

          .dialog-handler {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 3px;
            display: none; // wieder auf none
            z-index: 999;
          }

          .dragger_left {
            display: inline-block;
            width: 13px;
            height: calc(100% + 6px);
            background-color: #d2d2d2;
            float: left;
            margin-right: 5px;
            position: relative;
            top: -3px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            img {
              width: 8px;
              padding-left: 2px;
            }
          }

          .dragger_right {
            display: inline-block;
            width: 18px;
            height: calc(100% + 6px);
            border-right: 3px solid #d2d2d2;
            float: right;
            position: absolute;
            top: -3px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            right: 0px;

            .overlay-content {
              background-image: linear-gradient(
                to right,
                rgba(249, 249, 249, 0),
                #f9f9f9
              );
              display: inline-block;
              width: 18px;
              height: calc(100% - 6px);
              position: relative;
              margin-top: 3px;

              .half-circle {
                position: relative;
                top: 40%;
                background-color: transparent;
                right: -10px;
                .circle {
                  background: #d2d2d2;
                  display: inline-block;
                  margin: 0 1em 1em 0;
                  height: 24px;
                  width: 11px;
                  border-bottom-left-radius: 24px;
                  border-top-left-radius: 24px;
                  .icon {
                    padding-right: 0;
                    position: relative;
                    top: 3px;
                    right: -4px;
                    height: 11px;
                  }
                }
              }
            }
          }

          .data-wrapper {
            //padding-left: 6px;
            float: left;
            padding-top: 3px;
            width: calc(100% - 23px);
            height: 100%;
            position: relative;
            overflow: hidden;

            .move-controller {
              height: 100%;
              position: relative;
            }

            a,
            a:focus {
              text-decoration: none;
            }
            a:hover {
              text-decoration: underline;
            }

            .weeksss,
            .date-range {
              display: block;
            }

            .info-txt {
              //position: relative;
              //height: 100%;
              font-size: 10px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #8f9192;

              .icon {
                margin-top: 5px;
                display: inline-block;

                &:hover {
                  &.edit {
                    a {
                      color: green !important;
                    }
                  }
                  &.delete {
                    a {
                      color: red !important;
                    }
                  }
                }
              }

              &.bottom {
                position: absolute;
                bottom: 5px;
              }
            }

            input {
              border: none;
              color: variables.$freigeist_green;
              font-size: 14px;
              font-weight: bold;
              padding: 0px;
              margin: 0px;
              background: transparent;
            }

            .cursor {
              position: relative;
            }
            .cursor i {
              position: absolute;
              width: 1px;
              height: 80%;
              background-color: gray;
              left: 5px;
              top: 10%;
              animation-name: blink;
              animation-duration: 800ms;
              animation-iteration-count: infinite;
              opacity: 1;
            }

            .cursor input:focus + i {
              display: none;
            }

            @keyframes blink {
              from {
                opacity: 1;
              }
              to {
                opacity: 0;
              }
            }
          }
        }
      }
      .react-draggable {
        &.react-draggable-dragged {
          .data-wrapper {
            user-select: none;
          }
        }

        .seq_item.active {
          .dialog-handler {
            display: none;
          }

          border-color: variables.$freigeist-green;

          .dragger_left {
            background-color: variables.$freigeist_green;
          }
          .dragger_right {
            border-color: variables.$freigeist_green;

            .overlay-content {
              .half-circle {
                .circle {
                  background: variables.$freigeist_green;
                }
              }
            }

            .circle {
              background: variables.$freigeist_green;
            }
          }
        }

        &:hover .seq_item:not(.idle) {
          border-color: variables.$freigeist-green;

          .dialog-handler {
            display: block;
          }

          .dragger_left {
            background-color: variables.$freigeist_green;
          }
          .dragger_right {
            border-color: variables.$freigeist_green;

            .overlay-content {
              .half-circle {
                .circle {
                  background: variables.$freigeist_green;
                }
              }
            }

            .circle {
              background: variables.$freigeist_green;
            }
          }
        }

        &:hover .seq_item:is(.active) {
          .dialog-handler {
            display: none;
          }
        }
      }

      .date-grid {
        position: relative;
        height: 100%;
        margin-bottom: 10px;

        .childs-container {
          width: 100%;
          height: 132px;
          border-top: 1px solid #fff;
          display: flex;

          .childs-row {
            flex: none;
            height: 100%;
            width: 49px;
            border-left: 1px solid #cfcfcf;
            background-color: #e3e3e3;
            text-align: center;

            &.abweeks {
              &:nth-child(even) {
                background-color: rgba(58, 70, 76, 0.06);
              }
              &:nth-child(odd) {
                background-color: #e3e3e3;
              }
            }

            &.holiday {
              background-color: #bcbdbe;
              &.abweeks {
                background-color: #bcbdbe;
              }
            }

            &.end-month {
              border-left: 1px solid #848687;
            }

            small {
              font-size: 8px;
              position: relative;
              top: -8px;
            }
          }
        }

        /*
        .item-col
        {
          display: inline-block;
          border-right: 1px solid #afafaf;
          height: 100%;

        }
        */
      }
    }
  }
}
