.Main {
  position: relative;
  #loading-spinner {
    background-color: transparent;
    img {
      width: 60px;
      border-radius: 50%;
    }
  }
}
.blured-loader {
  filter: blur(3px);
}
