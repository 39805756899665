.info-box {
  width: 610px;
  min-height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;

  table {
    padding-left: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    .title {
      font-weight: bold;
    }
    .text {
      padding-left: 30px;
    }
  }
}
