.ReactModal__Content {
  padding: 0px !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border: none !important;
  .hour {
    padding-left: 5px;
  }
}

.ReactModal__Overlay {
  //background-color: rgba(255, 255, 255, 0.2) !important;
}

.ReactModal__Content--after-open {
  max-height: 85%;
}

.modal-header {
  padding-left: 15px;
  padding-top: 15px;
  font-weight: bold;
}

#timetable,
.ReactModalPortal {
  .defaul-tt-modal {
    background-color: #fff;
  }

  #timetableView {
    width: 700px;
    margin-right: auto;
    margin-left: auto;

    &.with_saturday {
      width: 820px;
    }

    // ab settings stuff
    .setting-ab-weeks {
      margin-left: auto;
      margin-right: auto;
      width: 610px;
      min-height: 46px;
      border-radius: 5px;
      background-color: #ffffff;
      margin-bottom: 57px;

      .info-wrapper {
        display: block;
        line-height: 46px;
        position: relative;

        span {
          display: inline-block;
        }

        .mode {
          font-weight: bold;
          padding-left: 16px;
        }

        .info {
          padding-left: 177px;
        }

        .edit {
          position: absolute;
          right: 11px;
          top: 6px;
          img {
            cursor: pointer;
            width: 23px;
          }
        }

        &.hide {
          display: none;
        }
      }

      .edit-wrapper {
        display: none;
        padding-left: 16px;
        padding-bottom: 40px;

        .mode {
          font-weight: bold;
          padding-bottom: 18px;
          line-height: 46px;
        }

        .item {
          padding-right: 15px;
          display: inline-block;
          color: rgba(58, 70, 76, 0.5);

          &.active {
            color: #3a464c;
          }
        }
        input {
          width: auto;
          height: auto;
        }
        label {
          padding-left: 5px;
        }

        .btn-wrapper {
          padding-top: 28px;
          margin-top: 0;

          .btn {
            box-shadow: none;
            border-radius: 5px;
            font-weight: bold;

            &.active {
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            }
          }
        }

        &.show {
          display: block;
        }
      }
    }
  }

  .step-text {
    width: 610px;
    margin-left: auto;
    margin-right: auto;
  }

  &.step,
  .progtrckr {
    max-width: 875px;

    .setting-ab-weeks {
      margin-top: 2rem;
    }

  }

  position: relative;

  .tt-day-wrapper {
    margin-top: 25px;
    width: 100%;
    .day-label {
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
      float: left;
      width: 110px;
      padding-right: 15px;

      .ab_week_info {
        font-size: 10px;
        color: #2a2e31;
        font-weight: normal;
        .left {
          float: left;
          width: 50%;
          text-align: center;
        }
        .right {
          float: right;
          width: 50%;
          text-align: center;
        }
      }

      &.first {
        width: 50px;
      }
    }
  }

  .modal-wrapper,
  .test {
    //background-color: #fff;
    max-width: 600px;

    .modal-header {
      height: 38px;
      padding: 0px 17px 10px 0px;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
    }

    .tab-main-wrapper {
      position: relative;
      margin-right: 7px;

      .closer {
        position: absolute;
        right: -5px;
        top: 39px;
        z-index: 9999;
        cursor: pointer;

        img {
          width: 25px;
        }
      }
    }

    .classes-subject-row {
      padding-left: 14px;
      padding-top: 14px;
      padding-right: 17px;
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: none;
      }

      .title {
        font-weight: bold;
      }

      .subjects {
        .item {
          transition: 0.1s;
          width: 154px;
          height: 38px;
          border-radius: 2px;
          background-color: #f2f2f2;
          display: inline-block;
          margin-right: 10px;
          cursor: pointer;
          margin-bottom: 10px;
          border: 2px solid #f2f2f2;

          .circle {
            width: 26px;
            height: 26px;
            border-radius: 13px;
            box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.25),
              inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
            display: inline-block;
            position: relative;
            top: 7px;
            margin-left: 7px;
            margin-right: 9px;
          }

          .name {
            font-weight: bold;
          }
          &.preselect {
            border: 2px solid #1ca0e2;
            margin-bottom: 7px;
            margin-right: 7px;
          }
        }
      }
    }
    &.hover_item {
      .subjects {
        .item {
          opacity: 0.5;
          &.active {
            opacity: 1;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }

  .tt-wrapper {
    width: 100%;

    .timetable-column {
      float: left;
      width: 110px;
      padding-right: 15px;

      &.label-column {
        width: 50px;
        .labels {
          .hour-label-item {
            height: 60px;
            margin-bottom: 3px;
            .hour {
              text-align: right;
              font-weight: bold;
              font-size: 21px;
              padding-top: 13px;
            }
            .text {
              text-align: right;
            }
            &:nth-child(6) {
              margin-bottom: 33px;
            }
          }
        }
      }

      .timetable-item {
        height: 60px;
        background-color: #e3e3e3;
        width: 100%;
        margin-bottom: 3px;
        cursor: pointer;
        overflow: hidden;

        &:nth-child(6) {
          margin-bottom: 33px;
        }

        &.add_element {
          background-color: transparent;
        }

        .item-inner {
          height: 60px;

          .add_row_item_wrapper {
            position: relative;
            top: 10px;

            .add {
              font-size: 60px;
              display: inline-block;
              color: #2a3134;
              opacity: 0.5;
            }
            .title {
              font-size: 12px;
              color: #3a464c;
              opacity: 0.5;
              display: inline-block;
              line-height: 15px;
              padding-left: 8px;
            }
          }

          .item-inner-wrapper {
            height: 100%;
            width: 100%;
            padding: 11px;

            &.half {
              padding: 0px;
              font-size: 9px;
              line-height: 12px;
              .left,
              .right {
                float: left;
                width: 48px;
                height: 60px;
              }
            }

            .placeholder {
              color: #3a464c;
              opacity: 0.5;
              padding-left: 2px;
            }
            .title {
              font-weight: bold;
            }
            .subject {
            }
          }
          &.fill {
            .placeholder {
              opacity: 1;
            }
          }
        }

        .hover-text {
          display: none;
        }

        &:hover {
          background-color: #4bc88c;
          color: #fff;

          .placeholder {
            display: none;
          }
          .hover-text {
            display: block;
          }
        }
      }
    }
  }
  .hidden {
    display: none;
  }

  .add_column {
    margin-top: 30px;
    text-align: right;
    margin-bottom: 30px;

    input {
      width: auto;
      height: auto;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    opacity: 0.4;
    background-color: #2a3134;
    margin-bottom: 15px;
  }
}

#registration {
  #timetableView {
    position: relative;
    left: -47px;

    &.newschool {
      left: 0;
    }
  }
}
