@use "../variables";

.backlink_bar_page {
  .arrow {
    display: inline-block;
    color: #1ca0e2;
    font-size: 40px;
    font-weight: 300;
    font-family: Arial;

    border-right: 1px solid #b3b4b5;
    height: 31px;
    padding-right: 10px;
    margin-right: 10px;
  }
  .headline {
    display: inline-block;
    font-size: 21px;
    font-weight: bold;
    color: #2a3134;
    position: relative;
    top: -4px;
  }
}

#topnav {
  width: 100%;
  height: 40px;
  background-color: #e3e3e3;
  position: relative;
  //padding-left: 20px;
  //padding-right: 20px;

  .item-right {
    float: right;
    position: absolute;
    z-index: 2;
  }

  .items {
    line-height: 40px;
    vertical-align: middle;
    //padding-left: $spacer*2;
    display: inline-block;
    width: 100%;

    .item {
      display: inline-block;
      padding-right: variables.$spacer;
      padding-left: variables.$spacer;
      text-align: center;
      a,
      a:focus {
        font-size: variables.$nav-font-size;
        line-height: variables.$nav-line-height;
        font-weight: variables.$nav-font-weight;
        color: variables.$nav-font-color;
      }

      &.logo {
        padding-left: 0px;
        padding-right: 0px;
        line-height: 40px;
        padding-bottom: 10px;

        img {
          border-radius: 50%;
          width: 25px;
          position: relative;
          top: 4px;
        }
      }

      &.account {
        cursor: pointer;
        position: relative;
        float: right;

        -webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
        transition: width 2s;

        .account-label {
          img {
            position: relative;
            top: -2px;
            padding-right: 5px;
          }
        }

        .account_items {
          position: absolute;
          z-index: 999;
          display: none;
          right: -15px;
          width: 129px;
          height: 122px;
          top: 31px;

          .top-marker-wrapper {
            width: 20px;
            margin-left: auto;
            margin-right: auto;
          }
          .top-marker {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 7px 7.5px;
            border-color: transparent transparent #ffffff transparent;
          }

          .items_wrapper {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);

            /*
            -moz-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
            */

            background-color: #ffffff;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            text-align: left;
          }

          .link_item {
            line-height: 30px;
          }

          a,
          a:focus {
            font-size: variables.$nav-font-size;
            font-weight: 400;
            color: #2a3134;

            &.greycolor {
              color: rgba(42, 49, 52, 0.4);
            }
          }
          a:hover {
            color: #1ca0e2;
          }
        }
        &:hover {
          .account_items {
            display: block;
          }
        }
      }
    }

    .active {
      background-color: variables.$main-grey;
      border-top: 4px solid variables.$color-water-blue;
      color: variables.$color-black;
    }
  }
}

#component_nav {
  background-color: variables.$main-grey;

  .content-inner-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;

    .navigation {
      width: 100%;
      display: inline-block;

      .item {
        display: inline-block;
        margin-right: variables.$spacer * 2;
        a,
        a:focus {
          font-size: variables.$nav-font-size;
          line-height: variables.$nav-line-height;
          font-weight: variables.$nav-font-weight;
          color: variables.$nav-font-color;
        }
      }

      .active {
        background-color: variables.$main-grey;
        border-bottom: 4px solid variables.$color-water-blue;
        color: variables.$color-black;
      }
    }
  }
}
