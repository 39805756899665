html {
  width: 100%;
  height: 100%;
}

body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-hyphens: auto;
  hyphens: auto;
  box-sizing: border-box;
  background-color: #f2f2f2;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  overflow-x: auto;
  font-family: Work Sans, sans-serif !important;
}

body.fullheight {
  height: 100%;
}

body.archiv_view {
  border: 10px solid #40b87e;
}

a, a:focus, a:hover {
  color: #1ca0e2;
  text-decoration: none;
}

a.anchor {
  cursor: pointer;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 21px;
  font-weight: bold;
}

h1.home {
  color: #4bc88c;
  font-size: 28px;
}

#loading-spinner {
  z-index: 99999;
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

#loading-spinner.running {
  display: block;
}

#loading-spinner .logo-container {
  text-align: center;
  width: 100%;
  position: relative;
  top: 35%;
}

#loading-spinner .logo-container .text {
  margin-top: 20px;
  font-weight: bold;
}

#loading-spinner .logo-container img {
  border-radius: 50%;
  width: 125px;
}

.wrapper, .content-inner-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (width <= 1350px) {
  .wrapper, .content-inner-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.wrapper.header, .content-inner-wrapper.header {
  padding-top: 0;
  padding-bottom: 0;
}

.content-wrapper {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.content-wrapper .inner-wrapper {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}

.background-white {
  background-color: #fff;
}

.backlink_bar_page .arrow {
  color: #1ca0e2;
  border-right: 1px solid #b3b4b5;
  height: 31px;
  margin-right: 10px;
  padding-right: 10px;
  font-family: Arial;
  font-size: 40px;
  font-weight: 300;
  display: inline-block;
}

.backlink_bar_page .headline {
  color: #2a3134;
  font-size: 21px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  top: -4px;
}

#topnav {
  background-color: #e3e3e3;
  width: 100%;
  height: 40px;
  position: relative;
}

#topnav .item-right {
  float: right;
  z-index: 2;
  position: absolute;
}

#topnav .items {
  vertical-align: middle;
  width: 100%;
  line-height: 40px;
  display: inline-block;
}

#topnav .items .item {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

#topnav .items .item a, #topnav .items .item a:focus {
  color: #25292b99;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

#topnav .items .item.logo {
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  line-height: 40px;
}

#topnav .items .item.logo img {
  border-radius: 50%;
  width: 25px;
  position: relative;
  top: 4px;
}

#topnav .items .item.account {
  cursor: pointer;
  float: right;
  transition: width 2s;
  position: relative;
}

#topnav .items .item.account .account-label img {
  padding-right: 5px;
  position: relative;
  top: -2px;
}

#topnav .items .item.account .account_items {
  z-index: 999;
  width: 129px;
  height: 122px;
  display: none;
  position: absolute;
  top: 31px;
  right: -15px;
}

#topnav .items .item.account .account_items .top-marker-wrapper {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
}

#topnav .items .item.account .account_items .top-marker {
  border: 7.5px solid #0000;
  border-top-width: 0;
  border-bottom: 7px solid #fff;
  width: 0;
  height: 0;
}

#topnav .items .item.account .account_items .items_wrapper {
  text-align: left;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  box-shadow: 0 10px 20px #00000080;
}

#topnav .items .item.account .account_items .link_item {
  line-height: 30px;
}

#topnav .items .item.account .account_items a, #topnav .items .item.account .account_items a:focus {
  color: #2a3134;
  font-size: 14px;
  font-weight: 400;
}

#topnav .items .item.account .account_items a.greycolor, #topnav .items .item.account .account_items a:focus.greycolor {
  color: #2a313466;
}

#topnav .items .item.account .account_items a:hover {
  color: #1ca0e2;
}

#topnav .items .item.account:hover .account_items {
  display: block;
}

#topnav .items .active {
  color: #25292b;
  background-color: #f2f2f2;
  border-top: 4px solid #1ca0e2;
}

#component_nav {
  background-color: #f2f2f2;
}

#component_nav .content-inner-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

#component_nav .content-inner-wrapper .navigation {
  width: 100%;
  display: inline-block;
}

#component_nav .content-inner-wrapper .navigation .item {
  margin-right: 40px;
  display: inline-block;
}

#component_nav .content-inner-wrapper .navigation .item a, #component_nav .content-inner-wrapper .navigation .item a:focus {
  color: #25292b99;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

#component_nav .content-inner-wrapper .navigation .active {
  color: #25292b;
  background-color: #f2f2f2;
  border-bottom: 4px solid #1ca0e2;
}

#login {
  max-width: 613px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
}

.layout-login {
  width: 100%;
  height: 100%;
}

.layout-login .left {
  float: left;
  background-image: url("freigeist-hero-image.314320d4.jpg");
  background-position: center;
  background-size: cover;
  width: 40%;
  height: 100%;
}

.layout-login .right {
  float: left;
  width: 60%;
  height: 100%;
  overflow: hidden;
}

.layout-login .right-wrapper {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

.layout-login .right-wrapper .footer {
  z-index: 20;
  text-align: right;
  color: #3a464c;
  opacity: .6;
  width: 50%;
  margin-right: 50px;
  position: absolute;
  bottom: 50px;
}

.layout-login .right-wrapper .footer a {
  color: #3a464c;
  opacity: .6;
}

.layout-login .header-section {
  width: 100%;
  margin-top: 25px;
}

.layout-login .header-section .left-section {
  float: left;
  width: 30%;
}

.layout-login .header-section .left-section .text {
  padding-left: 8px;
  position: relative;
  top: -15px;
}

.layout-login .header-section .right-section {
  float: right;
  text-align: right;
  width: 70%;
}

.layout-login .header-section .right-section .text {
  padding-right: 15px;
}

.layout-login .field-line {
  max-width: 400px;
}

.layout-login .field-line label {
  font-weight: normal;
}

.layout-login .field-line input {
  padding-left: 8px;
}

.layout-login .field-line #email, .layout-login .field-line #password {
  border-radius: 5px;
  width: 400px;
  height: 40px;
}

.layout-login .button-line .password_link a {
  color: #3a464c;
  margin-left: 20px;
  text-decoration: underline;
}

.layout-login .error-message.box {
  color: #fff;
  background-color: #cc5959;
  border-radius: 5px;
  width: 413px;
  height: 104px;
  margin-bottom: 20px;
}

.layout-login .error-message.box .text {
  padding-top: 9px;
  padding-left: 13px;
}

.layout-login .error-message.box .text a {
  color: #fff;
  text-decoration: underline;
}

.layout-login .error-message.box.notice_logout {
  background-color: #3a464c;
  height: 128px;
}

.layout-login .errorTypeWrongCredentials .field-line label {
  color: #cc5959;
}

.layout-login .errorTypeWrongCredentials .field-line #email, .layout-login .errorTypeWrongCredentials .field-line #password {
  color: #cc5959;
  border: 2px solid #cc5959;
}

.calendar-wrapper {
  margin-bottom: 290px;
}

.calendar-wrapper .controller {
  background-color: #e3e3e3;
  height: 55px;
}

.layer-note-empty-subject {
  z-index: 100;
  background-color: #f2f2f2;
  width: 100%;
  height: auto;
  padding-top: 10%;
  font-size: 16px;
  position: absolute;
  overflow: hidden;
}

.layer-note-empty-subject .content-layer {
  text-align: center;
  width: 400px;
  min-height: 200px;
  margin: auto;
}

.layer-note-empty-subject .content-layer .setup-link {
  color: #fff;
  background-color: #4bc88c;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 13px 10px;
  font-size: 14px;
  display: inline-block;
  box-shadow: 0 2px 10px #0003;
}

#sequence_calendar .date-desc {
  height: 25px;
  position: relative;
}

#sequence_calendar .date-desc .item {
  width: auto;
  position: absolute;
  top: 0;
}

#sequence_calendar .content {
  width: 2000px;
}

#sequence_calendar .no-move {
  cursor: default;
}

#sequence_calendar .row .cluster .item {
  color: #fff;
  background-color: #5f9ea0;
  border-top: 1px solid #fff;
  height: 132px;
}

#sequence_calendar .row .cal-grid {
  white-space: nowrap;
  -webkit-user-select: text;
  user-select: text;
  height: 100%;
  position: relative;
}

#sequence_calendar .row .cal-grid .items-grid {
  margin-top: 25px;
  position: absolute;
  top: 0;
}

#sequence_calendar .row .cal-grid .items-grid .items {
  height: auto;
  -webkit-user-select: none !important;
  user-select: none !important;
}

#sequence_calendar .row .cal-grid .items-grid .items .react-draggable {
  padding-bottom: 2px;
  position: absolute !important;
}

#sequence_calendar .row .cal-grid .items-grid .items .resizer div {
  width: 25px !important;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item {
  clear: both;
  color: #25292b;
  background-color: #fffc;
  border-top: 3px solid #0000;
  border-bottom: 3px solid #0000;
  border-radius: 3px;
  width: calc(100% - 5px);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  box-shadow: 0 1px 2px #0006;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dialog-handler {
  cursor: pointer;
  z-index: 999;
  display: none;
  position: absolute;
  top: 0;
  right: 3px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_left {
  float: left;
  background-color: #d2d2d2;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 13px;
  height: calc(100% + 6px);
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: -3px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_left img {
  width: 8px;
  padding-left: 2px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_right {
  float: right;
  border-right: 3px solid #d2d2d2;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 18px;
  height: calc(100% + 6px);
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 0;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_right .overlay-content {
  background-image: linear-gradient(to right, #f9f9f900, #f9f9f9);
  width: 18px;
  height: calc(100% - 6px);
  margin-top: 3px;
  display: inline-block;
  position: relative;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_right .overlay-content .half-circle {
  background-color: #0000;
  position: relative;
  top: 40%;
  right: -10px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_right .overlay-content .half-circle .circle {
  background: #d2d2d2;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  width: 11px;
  height: 24px;
  margin: 0 1em 1em 0;
  display: inline-block;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .dragger_right .overlay-content .half-circle .circle .icon {
  height: 11px;
  padding-right: 0;
  position: relative;
  top: 3px;
  right: -4px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper {
  float: left;
  width: calc(100% - 23px);
  height: 100%;
  padding-top: 3px;
  position: relative;
  overflow: hidden;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .move-controller {
  height: 100%;
  position: relative;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper a, #sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper a:focus {
  text-decoration: none;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper a:hover {
  text-decoration: underline;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .weeksss, #sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .date-range {
  display: block;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .info-txt {
  letter-spacing: normal;
  text-align: left;
  color: #8f9192;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .info-txt .icon {
  margin-top: 5px;
  display: inline-block;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .info-txt .icon:hover.edit a {
  color: green !important;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .info-txt .icon:hover.delete a {
  color: red !important;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .info-txt.bottom {
  position: absolute;
  bottom: 5px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper input {
  color: #4bc88c;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .cursor {
  position: relative;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .cursor i {
  opacity: 1;
  background-color: gray;
  width: 1px;
  height: 80%;
  animation-name: blink;
  animation-duration: .8s;
  animation-iteration-count: infinite;
  position: absolute;
  top: 10%;
  left: 5px;
}

#sequence_calendar .row .cal-grid .items-grid .seq_item .data-wrapper .cursor input:focus + i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

#sequence_calendar .row .cal-grid .react-draggable.react-draggable-dragged .data-wrapper {
  -webkit-user-select: none;
  user-select: none;
}

#sequence_calendar .row .cal-grid .react-draggable .seq_item.active {
  border-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable .seq_item.active .dialog-handler {
  display: none;
}

#sequence_calendar .row .cal-grid .react-draggable .seq_item.active .dragger_left {
  background-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable .seq_item.active .dragger_right {
  border-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable .seq_item.active .dragger_right .overlay-content .half-circle .circle, #sequence_calendar .row .cal-grid .react-draggable .seq_item.active .dragger_right .circle {
  background: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) {
  border-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) .dialog-handler {
  display: block;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) .dragger_left {
  background-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) .dragger_right {
  border-color: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) .dragger_right .overlay-content .half-circle .circle, #sequence_calendar .row .cal-grid .react-draggable:hover .seq_item:not(.idle) .dragger_right .circle {
  background: #4bc88c;
}

#sequence_calendar .row .cal-grid .react-draggable:hover .seq_item.active .dialog-handler {
  display: none;
}

#sequence_calendar .row .cal-grid .date-grid {
  height: 100%;
  margin-bottom: 10px;
  position: relative;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container {
  border-top: 1px solid #fff;
  width: 100%;
  height: 132px;
  display: flex;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row {
  text-align: center;
  background-color: #e3e3e3;
  border-left: 1px solid #cfcfcf;
  flex: none;
  width: 49px;
  height: 100%;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row.abweeks:nth-child(2n) {
  background-color: #3a464c0f;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row.abweeks:nth-child(odd) {
  background-color: #e3e3e3;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row.holiday, #sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row.holiday.abweeks {
  background-color: #bcbdbe;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row.end-month {
  border-left: 1px solid #848687;
}

#sequence_calendar .row .cal-grid .date-grid .childs-container .childs-row small {
  font-size: 8px;
  position: relative;
  top: -8px;
}

#ab-weeks-grid .childs-container {
  width: 100%;
  height: auto;
}

#ab-weeks-grid .childs-container .childs-row {
  text-align: center;
  min-height: 21px;
  font-size: 10px;
  display: inline-block;
  position: relative;
}

#ab-weeks-grid .childs-container .childs-row.active {
  height: 200px;
}

#ab-weeks-grid .childs-container .childs-row .inner-wrapper a {
  cursor: pointer;
  width: 100%;
  display: inline-block;
}

#ab-weeks-grid .childs-container .childs-row .inner-wrapper a:hover {
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
  display: inline-block;
}

#ab-weeks-grid .childs-container .childs-row .inner-wrapper a.A {
  color: #2a2e31;
}

#ab-weeks-grid .childs-container .childs-row .inner-wrapper a.B {
  color: #303538;
}

#ab-weeks-grid .childs-container .childs-row .dialog {
  text-align: left;
  width: 180px;
  height: 159px;
  white-space: initial;
  margin-top: 20px;
  font-size: 14px;
  line-height: 32px;
  display: none;
  position: absolute;
  top: 6px;
  left: -60px;
}

#ab-weeks-grid .childs-container .childs-row .dialog .triangle-wrapper {
  background-color: #0000;
  padding-left: 29px;
}

#ab-weeks-grid .childs-container .childs-row .dialog .triangle-wrapper .triangle-top {
  border: 5px solid #0000;
  border-top-width: 0;
  border-bottom: 7.5px solid #fff;
  width: 0;
  height: 0;
  position: relative;
  top: 0;
  left: 56px;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper {
  background-color: #fff;
  padding: 11px;
  box-shadow: 0 10px 20px #00000080;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .ab_changer {
  cursor: pointer;
  color: #4c5259;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .ab_changer.visible {
  color: #1ca0e2;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .icon {
  padding-right: 6px;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .icon img {
  visibility: hidden;
  width: 10px;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .icon img.visible {
  visibility: visible;
}

#ab-weeks-grid .childs-container .childs-row .dialog .dialog-wrapper .info {
  color: #4c5259;
  width: 135px;
  height: 45px;
  padding-top: 8px;
  padding-bottom: 12px;
  padding-left: 17px;
  font-size: 10px;
  line-height: 1.5;
}

#ab-weeks-grid .childs-container .childs-row .dialog.col-0 {
  left: auto;
}

#ab-weeks-grid .childs-container .childs-row .dialog.col-0 .triangle-top {
  left: -5px;
}

#ab-weeks-grid .childs-container .childs-row .dialog.holiday {
  width: 240px;
  left: -90px;
}

#ab-weeks-grid .childs-container .childs-row .dialog.holiday .triangle-wrapper {
  padding-left: 60px;
}

#ab-weeks-grid .childs-container .childs-row .dialog.active {
  display: block;
}

#sequence_wrapper {
  -webkit-user-select: text;
  user-select: text;
}

#sequence_wrapper .note-editor-wrapper .__editor_wrapper h1 {
  font-size: 52px;
  font-weight: bold;
  line-height: 58px;
}

#sequence_wrapper .note-editor-wrapper .__editor_wrapper h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
}

#sequence_wrapper .note-editor-wrapper .__editor_wrapper h3 {
  font-weight: bold;
}

#sequence_wrapper .note-editor-wrapper .fgEditor {
  min-height: 1100px;
}

#sequence_wrapper .content {
  background-color: #fff;
  border-radius: 5px;
  padding-top: 20px;
}

#sequence_wrapper .content.no-left-top {
  border-top-left-radius: 0;
}

#sequence_wrapper .sequence-meta-header-wrapper {
  position: relative;
}

#sequence_wrapper #component_nav .meta-wrapper {
  width: 100%;
}

#sequence_wrapper #component_nav .meta-wrapper .pager {
  font-weight: bold;
}

#sequence_wrapper #component_nav .meta-wrapper .pager a {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
}

#sequence_wrapper .details_wrapper {
  min-height: 150px;
  padding-bottom: 20px;
  display: none;
}

#sequence_wrapper .details_wrapper.show {
  display: block;
}

#sequence_wrapper .details_wrapper .didakt_wrapper {
  width: 100%;
  margin-top: 30px;
}

#sequence_wrapper .details_wrapper .didakt_wrapper .editor-wrapper {
  float: left;
  width: 480px;
  margin-right: 40px;
}

#sequence_wrapper .details_wrapper .didakt_wrapper .tags_wrapper {
  float: left;
  width: 400px;
}

#sequence_wrapper .details_wrapper .title {
  font-weight: bold;
}

#sequence_wrapper .details_wrapper .title.readonly {
  color: #25292b80;
}

#sequence_wrapper .details_wrapper .text {
  color: #2a3134b3;
}

#sequence_wrapper .details_wrapper .text.css-1rtrksz {
  padding: 0;
}

#sequence_wrapper .details_wrapper .text.css-1rtrksz .css-10odw4q {
  padding-right: 6px;
}

#sequence_wrapper #articluation_wrapper .slot-title-edit::placeholder {
  font-size: 21px;
  font-weight: bold;
}

#sequence_wrapper #slot_edit {
  height: 100%;
}

#sequence_wrapper #slot_edit .duringSelectionText {
  text-align: right;
  color: red;
  padding-right: 10px;
  display: inline-block;
}

#sequence_wrapper #slot_edit.content-inner-wrapper {
  padding-top: 0;
}

#sequence_wrapper #slot_edit .delete_dayslot_content {
  padding: 15px;
}

#sequence_wrapper #slot_edit .wrapper-slot-edit {
  background-color: #fff;
  width: 100%;
  min-width: 1120px;
}

#sequence_wrapper #slot_edit .timeslot-header {
  border-bottom: 1px solid #e3e3e3;
  display: none;
}

#sequence_wrapper #slot_edit .timeslot-header .slot-title {
  color: #25292b;
  font-weight: bold;
}

#sequence_wrapper #slot_edit .timeslot-header .slot-title input {
  color: #25292b;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

#sequence_wrapper #slot_edit .timeslot-header .flex {
  flex: 1;
  width: 50%;
}

#sequence_wrapper #slot_edit .flex-wrapper {
  width: 100%;
}

#sequence_wrapper #slot_edit .timeline-wrapper {
  float: left;
  background-color: #fff;
  width: 714px;
  height: 100%;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left {
  width: 30px;
  padding-top: 15px;
  font-size: 12px;
  font-weight: 300;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left .unit-desc {
  color: #25292b;
  opacity: .4;
  padding-left: 12px;
  font-size: 12px;
  display: inline-block;
  position: relative;
  top: -11px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left ol, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-left li {
  list-style-type: none;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left ol {
  counter-reset: marker;
  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
  padding-left: 0;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left li {
  box-sizing: border-box;
  border-top: 1px solid #e3e3e3;
  width: 5px;
  height: 20px;
  position: relative;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left li.long {
  border-top: 1px solid #e3e3e3;
  width: 10px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-left li:last-child {
  height: 0;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items {
  width: 670px;
  height: 100%;
  position: absolute;
  top: 15px;
  left: 30px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper {
  z-index: 1;
  width: 100%;
  top: 0;
  height: auto !important;
  position: absolute !important;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .pane-item, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .pane-item {
  box-sizing: border-box;
  padding: 2px 0;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .pane-item.no_move, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .pane-item.no_move {
  position: relative !important;
  transform: none !important;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .pane-item.drag_active .mover, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .pane-item.drag_active .mover {
  background-color: #1ca0e2 !important;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot {
  width: 100%;
  height: 100%;
  box-sizing: margin-box;
  background-color: #f2f2f2;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .header-edit, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .header-edit {
  z-index: 90;
  color: #25292b;
  font-size: 10px;
  position: absolute;
  top: 5px;
  right: 8px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .header-edit a, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .header-edit a {
  color: #25292b;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .header-edit a:hover, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .header-edit a:hover {
  color: #cc5959;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper {
  z-index: auto;
  float: right;
  width: 200px;
  height: 100%;
  position: relative;
  top: 10px;
  right: 0;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper .material-edit, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper .material-edit {
  width: 100%;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper.close, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper.close {
  overflow-x: auto;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper.on, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .editor-wrapper.on {
  position: absolute;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .mover, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .mover {
  -webkit-user-select: none;
  user-select: none;
  cursor: move;
  float: left;
  text-align: center;
  background-color: #e3e3e3;
  background-image: url("dragger.8ca81beb.png");
  background-position: 8px 17px;
  background-repeat: no-repeat;
  background-size: 10px 5px;
  width: 25px;
  height: 100%;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .mover:hover, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .mover:hover {
  background-color: #1ca0e2;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .mover .dragger, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .mover .dragger {
  text-align: center;
  width: 10px;
  display: none;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit {
  float: left;
  border: 1px;
  width: 423px;
  height: 100%;
  padding-left: 10px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit .header, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit .header {
  padding-top: 7px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit .text, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .slot-wrapper .content-edit .text {
  height: calc(100% - 35px);
  margin-bottom: 10px;
  overflow: auto;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .meta, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .meta {
  width: 100%;
  height: 10%;
  padding-top: 10px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .meta .edit, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .meta .edit {
  float: left;
  width: 70%;
  padding-left: 10px;
  padding-right: 10px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .meta .view, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .meta .view {
  float: left;
  text-align: right;
  width: 22%;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .meta .view .duration, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .meta .view .duration {
  padding-right: 15px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .meta .view .dragger, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .meta .view .dragger {
  cursor: move;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .content, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .content {
  width: 97%;
  height: 88%;
  padding-left: 10px;
  padding-right: 5px;
  overflow-x: auto;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items #timeslot-item-wrapper .timeline-slot .content textarea, #sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .timeslot-item-wrapper .timeline-slot .content textarea {
  resize: none;
  background-color: #0000;
  border: none;
  width: 99%;
  height: 60%;
  font-size: 14px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .item.selecting {
  background-color: #f2f2f2;
  border-top: none !important;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .time-row {
  border-top: 1px solid #e3e3e3;
  height: 99px;
  font-size: 12px;
}

#sequence_wrapper #slot_edit .timeline-wrapper #timeline-items .time-row .label {
  text-align: right;
}

#sequence_wrapper #slot_edit .attachments-wrapper {
  float: left;
  width: 406px;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section {
  margin-bottom: 30px;
  padding-left: 20px;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section.didakt-hinter .public-DraftEditorPlaceholder-inner {
  max-width: 300px;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section:first-child {
  padding-top: 15px;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section .title {
  font-weight: bold;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section .title.readonly {
  color: #25292b80;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section .text {
  color: #2a3134b3;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section .text.css-1rtrksz {
  padding: 0;
}

#sequence_wrapper #slot_edit .attachments-wrapper .section .text.css-1rtrksz .css-10odw4q {
  padding-right: 6px;
}

#settings select {
  width: auto;
}

#settings input {
  width: 200px;
}

#settings .frame {
  border: 1px solid gray;
  margin-top: 20px;
  padding: 20px;
}

.stepzilla .footer-buttons {
  display: none;
}

.stepzilla ol.progtrckr {
  padding: 0;
  list-style-type: none;
}

.stepzilla ol.progtrckr li {
  text-align: center;
  cursor: pointer;
  line-height: 4.5rem;
  display: inline-block;
}

.stepzilla ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (width <= 650px) {
  .stepzilla .progtrckr li span {
    display: none;
  }
}

.stepzilla .progtrckr em {
  padding-left: 1rem;
  font-weight: 700;
  display: none;
}

@media (width <= 650px) {
  .stepzilla .progtrckr em {
    display: inline;
  }
}

.stepzilla ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

.stepzilla ol.progtrckr li.progtrckr-doing {
  color: #000;
  border-bottom: 4px solid #ccc;
}

.stepzilla ol.progtrckr li.progtrckr-done {
  color: #000;
  border-bottom: 4px solid #5cb85c;
}

.stepzilla ol.progtrckr li:after {
  content: "  ";
}

.stepzilla ol.progtrckr li:before {
  float: left;
  position: relative;
  bottom: -3.7rem;
  left: 50%;
}

.stepzilla ol.progtrckr li.progtrckr-todo:before {
  content: "Ο";
  color: silver;
  background-color: #fff;
  width: 1.2em;
  line-height: 1.4em;
}

.stepzilla ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

.stepzilla ol.progtrckr li.progtrckr-doing:before {
  content: "•";
  color: #fff;
  background-color: #ccc;
  border-radius: 1.2em;
  width: 1.2em;
  line-height: 1.2em;
}

.stepzilla ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

.stepzilla ol.progtrckr li.progtrckr-done:before {
  content: "✓";
  color: #fff;
  background-color: #5cb85c;
  border-radius: 1.2em;
  width: 1.2em;
  line-height: 1.2em;
}

.stepzilla ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

#password.password-change {
  max-width: 630px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

#password.password-change .headline, #password.password-change .description {
  margin-bottom: 30px;
}

#password.password-change .btn-dark {
  width: auto;
}

#password #password_form {
  margin-top: 30px;
}

#password #password_form .after-field-content {
  padding-top: 5px;
  font-size: 12px;
  line-height: 16px;
}

#password label {
  font-size: 14px;
  font-weight: 400;
}

#password .strength-meter-wrapper {
  position: relative;
}

#password .strength-meter-text {
  z-index: 2;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: -3px;
  left: 320px;
}

#password .main-container {
  width: 400px;
  inset: 0;
}

#password .form-container {
  bottom: 100px;
}

#password .strength-meter {
  background: #ddd;
  border-radius: 2px;
  width: 300px;
  height: 3px;
  margin: 7px 0;
  position: relative;
  top: 5px;
}

#password .strength-meter:before, #password .strength-meter:after {
  content: "";
  height: inherit;
  z-index: 10;
  background: none;
  border: 0 solid #f2f2f2;
  border-width: 0 6px;
  width: calc(20% - 6px);
  display: block;
  position: absolute;
}

#password .strength-meter:before {
  left: calc(20% - 3px);
}

#password .strength-meter:after {
  right: calc(20% - 3px);
}

#password .strength-meter-fill {
  height: inherit;
  border-radius: inherit;
  background: none;
  width: 0;
  transition: width .5s ease-in-out, background .25s;
  position: absolute;
}

#password .strength-meter-fill[data-strength="0"] {
  background: #8b0000;
  width: 20%;
}

#password .strength-meter-fill[data-strength="1"] {
  background: #ff4500;
  width: 40%;
}

#password .strength-meter-fill[data-strength="2"] {
  background: orange;
  width: 60%;
}

#password .strength-meter-fill[data-strength="3"] {
  background: #9acd32;
  width: 80%;
}

#password .strength-meter-fill[data-strength="4"] {
  background: green;
  width: 100%;
}

#registration {
  color: #3a464c;
  padding-bottom: 80px;
}

#registration h1 {
  text-align: center;
  color: #40b87e;
  margin-top: 48px;
  margin-bottom: 20px;
  font-size: 20px;
}

#registration .step, #registration .progtrckr {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}

#registration.new_user .step, #registration.new_user .progtrckr {
  max-width: 630px;
}

#registration .progtrckr {
  text-align: center;
}

#registration .progtrckr li:first-child {
  text-align: left;
}

#registration .progtrckr li:last-child {
  text-align: right;
}

#registration .progtrckr li span {
  max-width: 80px;
  padding: 0;
  display: inline-block;
}

#registration .progtrckr li {
  vertical-align: top;
  width: 125px;
  line-height: 21px;
}

#registration .progtrckr li:before {
  bottom: 5px;
}

#registration .progtrckr li:first-child {
  left: 0;
}

#registration .progtrckr li:first-child em {
  position: relative;
  left: -8px;
}

#registration .progtrckr li:first-child:before {
  left: 0;
}

#registration .progtrckr li:last-child em {
  position: relative;
  right: -8px;
}

#registration .progtrckr li:last-child:before {
  float: right;
  left: 0;
}

#registration .progtrckr em {
  padding: 6px 0 0;
  font-style: normal;
  display: block;
}

#registration ol.progtrckr li:after {
  content: normal;
}

#registration ol.progtrckr li.progtrckr-doing {
  color: #40b87e;
}

#registration ol.progtrckr li.progtrckr-doing, #registration ol.progtrckr li.progtrckr-todo, #registration ol.progtrckr li.progtrckr-done {
  border-top: 2px solid #b4b9bb;
  border-bottom: none;
}

#registration ol.progtrckr li.progtrckr-doing:before, #registration ol.progtrckr li.progtrckr-todo:before, #registration ol.progtrckr li.progtrckr-done:before {
  color: #0000;
  content: "";
  background-color: #3a464c;
  border-radius: 7.5px;
  width: 8px;
  height: 8px;
  line-height: 0;
  display: inline-block;
}

#registration .step-text {
  margin-top: 70px;
  font-size: 20px;
  line-height: 28px;
}

#registration .step-text .title {
  font-weight: bold;
}

#registration .steps-buttons {
  text-align: right;
  margin-top: 30px;
}

#registration .btn-wrapper {
  width: 100%;
  margin-top: 31px;
}

#registration .btn-wrapper .back {
  float: left;
  width: 50%;
}

#registration .btn-wrapper .back a {
  color: #3a464c;
  text-decoration: underline;
}

#registration .btn-wrapper .next {
  text-align: right;
  float: right;
  width: 50%;
}

#subjects .subject_item_container {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  height: 145px;
  position: relative;
  box-shadow: 0 1px 3px #00000040;
}

#subjects .subject_item_container .picker {
  width: 100%;
  position: absolute;
  left: 450px;
}

#subjects .subject_item_container.inside {
  margin-bottom: 20px;
}

#subjects .subject_item_container .fields {
  padding: 15px;
}

#subjects .subject_item_container .color_wrapper {
  cursor: pointer;
  border-radius: 2px;
  width: 111px;
  height: 40px;
  position: relative;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#subjects .subject_item_container .btn_wrapper {
  padding-left: 15px;
}

#subjects .subject_item_container label {
  display: block;
}

#subjects .subject_item_container .file_color {
  float: left;
  width: 111px;
  padding-left: 15px;
}

#subjects .subject_item_container .field_input {
  float: left;
}

#subjects .subject_item_container .color_field {
  width: 111px;
}

#subjects .subject_item_container .inputfield {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: 400px;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#subjects .subject_list {
  margin-top: 36px;
}

#subjects .subject_list .subject_item {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  min-height: 40px;
  margin-bottom: 16px;
  line-height: 40px;
  box-shadow: 0 1px 3px #00000040;
}

#subjects .subject_list .subject_item .color {
  padding-left: 9px;
  padding-right: 9px;
  display: inline-block;
  position: relative;
  top: 7px;
}

#subjects .subject_list .subject_item .color .circle {
  border-radius: 13px;
  width: 26px;
  height: 26px;
  display: inline-block;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#subjects .subject_list .subject_item .title {
  color: #646769;
  font-size: 14px;
}

#subjects .subject_list .subject_item .actions {
  float: right;
  padding-right: 9px;
  display: inline-block;
}

#classrooms .classes_item_container {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  box-shadow: 0 1px 3px #00000040;
}

#classrooms .classes_item_container.inside {
  margin-bottom: 15px;
}

#classrooms .classes_item_container .fields, #classrooms .classes_item_container .subjects {
  padding: 10px;
}

#classrooms .classes_item_container .btn_wrapper {
  padding-bottom: 15px;
  padding-left: 15px;
}

#classrooms .classes_item_container label {
  display: block;
}

#classrooms .classes_item_container .inputfield {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: 580px;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#classrooms .subject_items {
  margin-top: 10px;
  margin-bottom: 10px;
}

#classrooms .subject_items .select_item {
  padding-right: 15px;
  display: inline-block;
}

#classrooms .subject_items .select_item .checkbox {
  background-image: linear-gradient(#fff, #f7f7f7 48%, #f0f0f0 51%, #f1f1f1);
  border: 1px solid #00000059;
  border-radius: 2px;
  width: auto;
  height: auto;
  margin-right: 10px;
  box-shadow: 0 1px #00000026;
}

#classrooms .classes_list {
  margin-top: 36px;
}

#classrooms .classes_list .classes_item {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  min-height: 40px;
  margin-bottom: 15px;
  line-height: 40px;
  position: relative;
  box-shadow: 0 1px 3px #00000040;
}

#classrooms .classes_list .classes_item .title {
  color: #646769;
  padding-left: 9px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
}

#classrooms .classes_list .classes_item .subjects .text-item {
  padding-right: 0;
}

#classrooms .classes_list .classes_item .subjects .text-item:last-child .comma {
  display: none;
}

#classrooms .classes_list .classes_item .actions {
  float: right;
  z-index: 3;
  background-color: #fffc;
  padding-left: 10px;
  padding-right: 9px;
  display: inline-block;
  position: absolute;
  right: 0;
}

.ReactModal__Content {
  box-shadow: 0 1px 3px #00000040;
  border: none !important;
  padding: 0 !important;
}

.ReactModal__Content .hour {
  padding-left: 5px;
}

.ReactModal__Content--after-open {
  max-height: 85%;
}

.modal-header {
  padding-top: 15px;
  padding-left: 15px;
  font-weight: bold;
}

#timetable, .ReactModalPortal {
  position: relative;
}

#timetable .defaul-tt-modal, .ReactModalPortal .defaul-tt-modal {
  background-color: #fff;
}

#timetable #timetableView, .ReactModalPortal #timetableView {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

#timetable #timetableView.with_saturday, .ReactModalPortal #timetableView.with_saturday {
  width: 820px;
}

#timetable #timetableView .setting-ab-weeks, .ReactModalPortal #timetableView .setting-ab-weeks {
  background-color: #fff;
  border-radius: 5px;
  width: 610px;
  min-height: 46px;
  margin-bottom: 57px;
  margin-left: auto;
  margin-right: auto;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper {
  line-height: 46px;
  display: block;
  position: relative;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper span, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper span {
  display: inline-block;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper .mode, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper .mode {
  padding-left: 16px;
  font-weight: bold;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper .info, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper .info {
  padding-left: 177px;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper .edit, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper .edit {
  position: absolute;
  top: 6px;
  right: 11px;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper .edit img, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper .edit img {
  cursor: pointer;
  width: 23px;
}

#timetable #timetableView .setting-ab-weeks .info-wrapper.hide, .ReactModalPortal #timetableView .setting-ab-weeks .info-wrapper.hide {
  display: none;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper {
  padding-bottom: 40px;
  padding-left: 16px;
  display: none;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .mode, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .mode {
  padding-bottom: 18px;
  font-weight: bold;
  line-height: 46px;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .item, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .item {
  color: #3a464c80;
  padding-right: 15px;
  display: inline-block;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .item.active, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .item.active {
  color: #3a464c;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper input, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper input {
  width: auto;
  height: auto;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper label, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper label {
  padding-left: 5px;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper {
  margin-top: 0;
  padding-top: 28px;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper .btn, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper .btn {
  box-shadow: none;
  border-radius: 5px;
  font-weight: bold;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper .btn.active, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper .btn-wrapper .btn.active {
  box-shadow: 0 2px 4px #00000080;
}

#timetable #timetableView .setting-ab-weeks .edit-wrapper.show, .ReactModalPortal #timetableView .setting-ab-weeks .edit-wrapper.show {
  display: block;
}

#timetable .step-text, .ReactModalPortal .step-text {
  width: 610px;
  margin-left: auto;
  margin-right: auto;
}

#timetable.step, #timetable .progtrckr, .ReactModalPortal.step, .ReactModalPortal .progtrckr {
  max-width: 875px;
}

#timetable.step .setting-ab-weeks, #timetable .progtrckr .setting-ab-weeks, .ReactModalPortal.step .setting-ab-weeks, .ReactModalPortal .progtrckr .setting-ab-weeks {
  margin-top: 2rem;
}

#timetable .tt-day-wrapper, .ReactModalPortal .tt-day-wrapper {
  width: 100%;
  margin-top: 25px;
}

#timetable .tt-day-wrapper .day-label, .ReactModalPortal .tt-day-wrapper .day-label {
  text-align: center;
  float: left;
  width: 110px;
  margin-bottom: 5px;
  padding-right: 15px;
  font-weight: bold;
}

#timetable .tt-day-wrapper .day-label .ab_week_info, .ReactModalPortal .tt-day-wrapper .day-label .ab_week_info {
  color: #2a2e31;
  font-size: 10px;
  font-weight: normal;
}

#timetable .tt-day-wrapper .day-label .ab_week_info .left, .ReactModalPortal .tt-day-wrapper .day-label .ab_week_info .left {
  float: left;
  text-align: center;
  width: 50%;
}

#timetable .tt-day-wrapper .day-label .ab_week_info .right, .ReactModalPortal .tt-day-wrapper .day-label .ab_week_info .right {
  float: right;
  text-align: center;
  width: 50%;
}

#timetable .tt-day-wrapper .day-label.first, .ReactModalPortal .tt-day-wrapper .day-label.first {
  width: 50px;
}

#timetable .modal-wrapper, #timetable .test, .ReactModalPortal .modal-wrapper, .ReactModalPortal .test {
  max-width: 600px;
}

#timetable .modal-wrapper .modal-header, #timetable .test .modal-header, .ReactModalPortal .modal-wrapper .modal-header, .ReactModalPortal .test .modal-header {
  color: #fff;
  height: 38px;
  padding: 0 17px 10px 0;
  font-size: 32px;
  font-weight: bold;
}

#timetable .modal-wrapper .tab-main-wrapper, #timetable .test .tab-main-wrapper, .ReactModalPortal .modal-wrapper .tab-main-wrapper, .ReactModalPortal .test .tab-main-wrapper {
  margin-right: 7px;
  position: relative;
}

#timetable .modal-wrapper .tab-main-wrapper .closer, #timetable .test .tab-main-wrapper .closer, .ReactModalPortal .modal-wrapper .tab-main-wrapper .closer, .ReactModalPortal .test .tab-main-wrapper .closer {
  z-index: 9999;
  cursor: pointer;
  position: absolute;
  top: 39px;
  right: -5px;
}

#timetable .modal-wrapper .tab-main-wrapper .closer img, #timetable .test .tab-main-wrapper .closer img, .ReactModalPortal .modal-wrapper .tab-main-wrapper .closer img, .ReactModalPortal .test .tab-main-wrapper .closer img {
  width: 25px;
}

#timetable .modal-wrapper .classes-subject-row, #timetable .test .classes-subject-row, .ReactModalPortal .modal-wrapper .classes-subject-row, .ReactModalPortal .test .classes-subject-row {
  border-bottom: 1px solid #e3e3e3;
  padding: 14px 17px 20px 14px;
}

#timetable .modal-wrapper .classes-subject-row:last-child, #timetable .test .classes-subject-row:last-child, .ReactModalPortal .modal-wrapper .classes-subject-row:last-child, .ReactModalPortal .test .classes-subject-row:last-child {
  border-bottom: none;
}

#timetable .modal-wrapper .classes-subject-row .title, #timetable .test .classes-subject-row .title, .ReactModalPortal .modal-wrapper .classes-subject-row .title, .ReactModalPortal .test .classes-subject-row .title {
  font-weight: bold;
}

#timetable .modal-wrapper .classes-subject-row .subjects .item, #timetable .test .classes-subject-row .subjects .item, .ReactModalPortal .modal-wrapper .classes-subject-row .subjects .item, .ReactModalPortal .test .classes-subject-row .subjects .item {
  cursor: pointer;
  background-color: #f2f2f2;
  border: 2px solid #f2f2f2;
  border-radius: 2px;
  width: 154px;
  height: 38px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: all .1s;
  display: inline-block;
}

#timetable .modal-wrapper .classes-subject-row .subjects .item .circle, #timetable .test .classes-subject-row .subjects .item .circle, .ReactModalPortal .modal-wrapper .classes-subject-row .subjects .item .circle, .ReactModalPortal .test .classes-subject-row .subjects .item .circle {
  border-radius: 13px;
  width: 26px;
  height: 26px;
  margin-left: 7px;
  margin-right: 9px;
  display: inline-block;
  position: relative;
  top: 7px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#timetable .modal-wrapper .classes-subject-row .subjects .item .name, #timetable .test .classes-subject-row .subjects .item .name, .ReactModalPortal .modal-wrapper .classes-subject-row .subjects .item .name, .ReactModalPortal .test .classes-subject-row .subjects .item .name {
  font-weight: bold;
}

#timetable .modal-wrapper .classes-subject-row .subjects .item.preselect, #timetable .test .classes-subject-row .subjects .item.preselect, .ReactModalPortal .modal-wrapper .classes-subject-row .subjects .item.preselect, .ReactModalPortal .test .classes-subject-row .subjects .item.preselect {
  border: 2px solid #1ca0e2;
  margin-bottom: 7px;
  margin-right: 7px;
}

#timetable .modal-wrapper.hover_item .subjects .item, #timetable .test.hover_item .subjects .item, .ReactModalPortal .modal-wrapper.hover_item .subjects .item, .ReactModalPortal .test.hover_item .subjects .item {
  opacity: .5;
}

#timetable .modal-wrapper.hover_item .subjects .item.active, #timetable .test.hover_item .subjects .item.active, .ReactModalPortal .modal-wrapper.hover_item .subjects .item.active, .ReactModalPortal .test.hover_item .subjects .item.active {
  opacity: 1;
  box-shadow: 0 1px 10px #00000073;
}

#timetable .tt-wrapper, .ReactModalPortal .tt-wrapper {
  width: 100%;
}

#timetable .tt-wrapper .timetable-column, .ReactModalPortal .tt-wrapper .timetable-column {
  float: left;
  width: 110px;
  padding-right: 15px;
}

#timetable .tt-wrapper .timetable-column.label-column, .ReactModalPortal .tt-wrapper .timetable-column.label-column {
  width: 50px;
}

#timetable .tt-wrapper .timetable-column.label-column .labels .hour-label-item, .ReactModalPortal .tt-wrapper .timetable-column.label-column .labels .hour-label-item {
  height: 60px;
  margin-bottom: 3px;
}

#timetable .tt-wrapper .timetable-column.label-column .labels .hour-label-item .hour, .ReactModalPortal .tt-wrapper .timetable-column.label-column .labels .hour-label-item .hour {
  text-align: right;
  padding-top: 13px;
  font-size: 21px;
  font-weight: bold;
}

#timetable .tt-wrapper .timetable-column.label-column .labels .hour-label-item .text, .ReactModalPortal .tt-wrapper .timetable-column.label-column .labels .hour-label-item .text {
  text-align: right;
}

#timetable .tt-wrapper .timetable-column.label-column .labels .hour-label-item:nth-child(6), .ReactModalPortal .tt-wrapper .timetable-column.label-column .labels .hour-label-item:nth-child(6) {
  margin-bottom: 33px;
}

#timetable .tt-wrapper .timetable-column .timetable-item, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item {
  cursor: pointer;
  background-color: #e3e3e3;
  width: 100%;
  height: 60px;
  margin-bottom: 3px;
  overflow: hidden;
}

#timetable .tt-wrapper .timetable-column .timetable-item:nth-child(6), .ReactModalPortal .tt-wrapper .timetable-column .timetable-item:nth-child(6) {
  margin-bottom: 33px;
}

#timetable .tt-wrapper .timetable-column .timetable-item.add_element, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item.add_element {
  background-color: #0000;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner {
  height: 60px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper {
  position: relative;
  top: 10px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper .add, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper .add {
  color: #2a3134;
  opacity: .5;
  font-size: 60px;
  display: inline-block;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper .title, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .add_row_item_wrapper .title {
  color: #3a464c;
  opacity: .5;
  padding-left: 8px;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper {
  width: 100%;
  height: 100%;
  padding: 11px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half {
  padding: 0;
  font-size: 9px;
  line-height: 12px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half .left, #timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half .right, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half .left, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper.half .right {
  float: left;
  width: 48px;
  height: 60px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper .placeholder, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper .placeholder {
  color: #3a464c;
  opacity: .5;
  padding-left: 2px;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper .title, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner .item-inner-wrapper .title {
  font-weight: bold;
}

#timetable .tt-wrapper .timetable-column .timetable-item .item-inner.fill .placeholder, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .item-inner.fill .placeholder {
  opacity: 1;
}

#timetable .tt-wrapper .timetable-column .timetable-item .hover-text, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item .hover-text {
  display: none;
}

#timetable .tt-wrapper .timetable-column .timetable-item:hover, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item:hover {
  color: #fff;
  background-color: #4bc88c;
}

#timetable .tt-wrapper .timetable-column .timetable-item:hover .placeholder, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item:hover .placeholder {
  display: none;
}

#timetable .tt-wrapper .timetable-column .timetable-item:hover .hover-text, .ReactModalPortal .tt-wrapper .timetable-column .timetable-item:hover .hover-text {
  display: block;
}

#timetable .hidden, .ReactModalPortal .hidden {
  display: none;
}

#timetable .add_column, .ReactModalPortal .add_column {
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
}

#timetable .add_column input, .ReactModalPortal .add_column input {
  width: auto;
  height: auto;
}

#timetable .line, .ReactModalPortal .line {
  opacity: .4;
  background-color: #2a3134;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;
}

#registration #timetableView {
  position: relative;
  left: -47px;
}

#registration #timetableView.newschool {
  left: 0;
}

#school, #region-schooltype {
  max-width: 610px;
}

#school .head, #region-schooltype .head {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

#school .head.second, #region-schooltype .head.second {
  margin-top: 42px;
}

#school .step-text, #region-schooltype .step-text {
  padding-bottom: 15px;
}

#school .dashboard-btns, #region-schooltype .dashboard-btns {
  padding-top: 15px;
}

#school .school-label, #region-schooltype .school-label {
  color: #3a464c;
  height: 22px;
  font-size: 14px;
  line-height: 1.57;
}

#school .school-input, #region-schooltype .school-input {
  color: #3a464c;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
  background-color: #f2f2f2 !important;
  padding-left: 13px !important;
}

#school .school-input.checkbox, #region-schooltype .school-input.checkbox {
  width: auto;
  height: auto;
}

#school .form-wrapper, #region-schooltype .form-wrapper {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  box-shadow: 0 1px 3px #00000040;
}

#school .form-wrapper .text, #region-schooltype .form-wrapper .text {
  padding: 16px 20px 20px;
  font-weight: 700;
}

#school .form-wrapper .field-group, #region-schooltype .form-wrapper .field-group {
  width: 575px;
  padding: 10px;
}

#school .form-wrapper .field-group.dropdowns, #region-schooltype .form-wrapper .field-group.dropdowns {
  width: 590px;
}

#school .form-wrapper .field-group.refs, #region-schooltype .form-wrapper .field-group.refs {
  width: 360px;
}

#school .form-wrapper .field-group label, #region-schooltype .form-wrapper .field-group label {
  color: #3a464c;
  height: 22px;
  font-size: 14px;
  line-height: 1.57;
}

#school .form-wrapper .field-group input, #region-schooltype .form-wrapper .field-group input {
  color: #3a464c;
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  padding-left: 13px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#school .form-wrapper .field-group input.checkbox, #region-schooltype .form-wrapper .field-group input.checkbox {
  width: auto;
  height: auto;
  margin: 3px 13px 3px 4px;
}

#school .form-wrapper .field-group .col-street, #region-schooltype .form-wrapper .field-group .col-street {
  float: left;
  width: 465px;
}

#school .form-wrapper .field-group .col-street-nr, #region-schooltype .form-wrapper .field-group .col-street-nr {
  float: left;
  width: 85px;
  margin-left: 25px;
}

#school .form-wrapper .field-group .col-zip, #region-schooltype .form-wrapper .field-group .col-zip {
  float: left;
  width: 85px;
  margin-right: 25px;
}

#school .form-wrapper .field-group .col-location, #region-schooltype .form-wrapper .field-group .col-location {
  float: left;
  width: 465px;
}

#school .form-wrapper .field-group .col-region, #school .form-wrapper .field-group .col-schooltype, #region-schooltype .form-wrapper .field-group .col-region, #region-schooltype .form-wrapper .field-group .col-schooltype {
  width: 289px;
}

#school .form-wrapper .field-group .col-region, #region-schooltype .form-wrapper .field-group .col-region {
  float: left;
  margin-right: 5px;
}

#school .form-wrapper .field-group .col-schooltype, #region-schooltype .form-wrapper .field-group .col-schooltype {
  float: left;
  margin-left: 5px;
}

.region-schooltype-btns {
  padding-top: 15px;
}

#region-schooltype .step-text, #student .step-text {
  margin-top: 0;
}

#region-schooltype .head, #student .head {
  padding-bottom: 0;
}

.info-box {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  min-height: 40px;
  margin-bottom: 30px;
  box-shadow: 0 1px 3px #00000040;
}

.info-box table {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
}

.info-box table .title {
  font-weight: bold;
}

.info-box table .text {
  padding-left: 30px;
}

#pdf-dashboard .row-wrapper {
  width: 613px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

#pdf-dashboard .row-wrapper.list .block {
  margin-bottom: 20px;
}

#pdf-dashboard .row-wrapper .block {
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  padding: 15px;
  box-shadow: 0 1px 3px #00000040;
}

#pdf-dashboard .row-wrapper .block.blank {
  box-shadow: none;
  background-color: #0000;
}

#pdf-dashboard .row-wrapper .block .title {
  font-weight: bold;
}

#pdf-dashboard .row-wrapper .block .label {
  padding-bottom: 5px;
}

#pdf-dashboard .row-wrapper .block .dropdown-wrapper {
  width: 100%;
}

#pdf-dashboard .row-wrapper .block .dropdown-wrapper .left, #pdf-dashboard .row-wrapper .block .dropdown-wrapper .right {
  float: left;
  width: 50%;
}

#pdf-dashboard .row-wrapper .block .dropdown {
  width: 284px;
}

#settings-dashboard h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 28px;
}

#settings-dashboard .setting-row-wrapper {
  width: 613px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

#settings-dashboard .setting-row-wrapper .description {
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
}

#settings-dashboard .setting-row-wrapper #classrooms .classes_list, #settings-dashboard .setting-row-wrapper #subjects .subject_list {
  margin-top: 15px;
}

#settings-dashboard .setting-row-wrapper .btn-wrapper.steps-btns {
  display: none;
}

#settings-dashboard .setting-row-wrapper.timetable {
  width: 820px;
}

#settings-dashboard .setting-row-wrapper.timetable .head-wrapper {
  width: 613px;
  margin-left: auto;
  margin-right: auto;
}

#settings-dashboard .setting-row-wrapper.timetable #timetableView {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

#settings-dashboard .setting-row-wrapper.timetable #timetableView.with_saturday {
  width: 820px;
}

#settings-dashboard .setting-row-wrapper #password_form {
  margin-top: 0;
}

#settings-dashboard .setting-row-wrapper #school .policy-data, #settings-dashboard .setting-row-wrapper #school #student {
  display: none;
}

#settings-dashboard .action_form_wrapper {
  background-color: #fff;
  border-radius: 2px;
  width: 610px;
  min-height: 40px;
  box-shadow: 0 1px 3px #00000040;
}

#settings-dashboard .action_form_wrapper .input_radio {
  width: auto;
  height: inherit;
  margin-right: 9px;
}

#settings-dashboard .action_form_wrapper .radio-inline {
  padding-right: 20px;
}

#settings-dashboard .action_form_wrapper .edit {
  padding-top: 20px;
  padding-left: 14px;
}

#settings-dashboard .action_form_wrapper .inputfield {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: 570px;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

#settings-dashboard .action_form_wrapper .btn-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

#settings-dashboard .action_form_wrapper .readonly {
  line-height: 40px;
}

#settings-dashboard .action_form_wrapper .readonly .label {
  padding-left: 14px;
  padding-right: 40px;
  font-weight: bold;
}

#settings-dashboard .action_form_wrapper .readonly .actions {
  padding-right: 14px;
}

#settings-dashboard #region-schooltype .description {
  padding-bottom: 20px;
}

.message-box {
  line-height: 21px;
}

.message-box.notice {
  color: #fff;
  background-color: #cc5959;
  margin-bottom: 15px;
  padding: 15px;
}

.message-box.notice .btn-dark {
  width: auto;
  font-weight: 700;
}

.message-box.message {
  color: #fff;
  margin-bottom: 15px;
  padding: 15px;
}

.editor-wrapper {
  position: relative;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.cleanSelect {
  z-index: auto;
  width: 100%;
  position: absolute;
}

.cleanSelect .react-select__menu-notice, .cleanSelect .react-select__menu-notice--no-options {
  display: none;
}

.cleanSelect .react-select__option, .cleanSelect .react-select__option--is-focused {
  cursor: pointer;
  padding: 0 8px;
}

.cleanSelect .react-select__menu-list, .cleanSelect .react-select__menu-list--is-multi {
  padding-top: 4px;
  padding-bottom: 4px;
}

.cleanSelect .react-select__value-container, .cleanSelect .react-select__value-container--is-multi, .cleanSelect .react-select__value-container--has-value {
  padding: 0 10px 0 0;
}

.cleanSelect .react-select__menu {
  width: auto;
  margin-top: 0;
}

.cleanSelect .react-select__control, .cleanSelect .react-select__control--is-focused {
  box-shadow: none !important;
  background-color: #0000 !important;
  border: none !important;
}

.cleanSelect .react-select__multi-value__label {
  white-space: normal;
  min-height: 25px;
  padding: 2px 2px 2px 5px;
}

.cleanSelect .Select-arrow-zone {
  display: none;
}

.cleanSelect .Select-menu-outer {
  display: none;
  z-index: 9999 !important;
}

.cleanSelect .react-select__indicators {
  display: none;
}

.cleanSelect .react-select__control {
  background-color: #0000;
  border: none;
}

.pdf_download {
  margin-top: 25px;
  font-weight: bold;
}

.pdf_download a {
  text-decoration: underline;
  color: #2a3134 !important;
}

.pdf_download .icon-wrapper .icon {
  padding-right: 8px;
}

.pdf_download .text-wrapper {
  position: relative;
  top: -10px;
}

.paymentModal, .commonModal {
  position: absolute;
  inset: 40px;
}

.paymentModal.firstTimeModal, .commonModal.firstTimeModal {
  overflow: hidden auto;
}

.paymentModal .paymenModalContent, .paymentModal .commonModalContent, .commonModal .paymenModalContent, .commonModal .commonModalContent {
  background-color: #fff;
  width: 610px;
  position: relative;
  box-shadow: 0 0 25px #0000004d;
}

.paymentModal .paymenModalContent .textlink, .paymentModal .commonModalContent .textlink, .commonModal .paymenModalContent .textlink, .commonModal .commonModalContent .textlink {
  color: #25292b;
  text-decoration: underline;
}

.paymentModal .paymenModalContent .closer, .paymentModal .commonModalContent .closer, .commonModal .paymenModalContent .closer, .commonModal .commonModalContent .closer {
  z-index: 99;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
}

.paymentModal .paymenModalContent .closer img, .paymentModal .commonModalContent .closer img, .commonModal .paymenModalContent .closer img, .commonModal .commonModalContent .closer img {
  width: 25px;
}

.paymentModal .paymenModalContent .text-wrapper, .paymentModal .commonModalContent .text-wrapper, .commonModal .paymenModalContent .text-wrapper, .commonModal .commonModalContent .text-wrapper {
  padding: 40px 50px 55px;
}

.paymentModal .paymenModalContent .text-wrapper .intro-text, .paymentModal .commonModalContent .text-wrapper .intro-text, .commonModal .paymenModalContent .text-wrapper .intro-text, .commonModal .commonModalContent .text-wrapper .intro-text {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.4;
}

.paymentModal .paymenModalContent .text-wrapper .ref-text, .paymentModal .commonModalContent .text-wrapper .ref-text, .commonModal .paymenModalContent .text-wrapper .ref-text, .commonModal .commonModalContent .text-wrapper .ref-text {
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.57;
}

.paymentModal .paymenModalContent .text-wrapper .btn-wrapper, .paymentModal .commonModalContent .text-wrapper .btn-wrapper, .commonModal .paymenModalContent .text-wrapper .btn-wrapper, .commonModal .commonModalContent .text-wrapper .btn-wrapper {
  padding-top: 15px;
  padding-bottom: 30px;
}

.paymentModal .paymenModalContent .text-wrapper .btn-wrapper .btn, .paymentModal .commonModalContent .text-wrapper .btn-wrapper .btn, .commonModal .paymenModalContent .text-wrapper .btn-wrapper .btn, .commonModal .commonModalContent .text-wrapper .btn-wrapper .btn {
  font-family: Work Sans, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.paymentModal .paymenModalContent .text-wrapper .btn-wrapper .abo_cancel_link, .paymentModal .commonModalContent .text-wrapper .btn-wrapper .abo_cancel_link, .commonModal .paymenModalContent .text-wrapper .btn-wrapper .abo_cancel_link, .commonModal .commonModalContent .text-wrapper .btn-wrapper .abo_cancel_link {
  padding-left: 30px;
  display: inline-block;
}

.paymentModal .paymenModalContent .text-wrapper .btn-wrapper .abo_cancel_link a, .paymentModal .commonModalContent .text-wrapper .btn-wrapper .abo_cancel_link a, .commonModal .paymenModalContent .text-wrapper .btn-wrapper .abo_cancel_link a, .commonModal .commonModalContent .text-wrapper .btn-wrapper .abo_cancel_link a {
  color: #2a3134;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-decoration: underline;
}

.commonModal {
  margin-right: -50%;
  inset: 45% auto auto 50%;
  transform: translate(-50%, -50%);
}

.commonModal.transparent .commonModalContent {
  box-shadow: none;
  background-color: #0000;
}

.topModal {
  width: 610px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50px;
  left: 0;
  right: 0;
  transform: none;
}

.ReactModal__Overlay.paymentOverlay, .ReactModal__Overlay.commonOverlay {
  position: fixed;
  inset: 0;
  background-color: #40b87eb3 !important;
}

.isblured {
  filter: blur(3px);
}

.first_time_note .text-wrapper {
  padding-top: 43px !important;
}

.first_time_note .text-wrapper a.mailto {
  color: #3a464c;
}

.first_time_note .text-wrapper a.mailto:hover {
  text-decoration: underline;
}

.first_time_note .video-wrapper {
  padding-top: 10px;
  padding-bottom: 15px;
}

.first_time_note .btn {
  color: #fff;
  text-align: center;
  background-color: #4bc88c;
  border-radius: 5px;
  width: 116px;
  height: 40px;
  margin-top: 13px;
  font-size: 14px;
  font-weight: bold;
  display: block;
  box-shadow: 0 2px 4px #00000080;
}

.note-setup-newyear-layer {
  z-index: 20;
  background-color: #ffffff80;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
}

.note-setup-newyear {
  background-color: #4bc88c;
  border-radius: 5px;
  width: 289px;
  height: 150px;
  margin: 50px auto;
  box-shadow: 0 2px 10px #0003;
}

.note-setup-newyear .inner-wrapper {
  padding: 16px 20px 20px;
}

.note-setup-newyear .inner-wrapper .text {
  color: #fff;
  padding-bottom: 10px;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
}

.note-setup-newyear .inner-wrapper .btn-wrapper {
  text-align: center;
}

.note-setup-newyear .inner-wrapper .btn-wrapper .link-wrapper a {
  color: #fff;
  background-color: #00000026;
  border-radius: 2px;
  width: 249px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  display: block;
}

.note-setup-newyear .inner-wrapper .btn-wrapper .link-wrapper a .linktext {
  padding-top: 10px;
  display: block;
}

.note-setup-newyear .inner-wrapper .btn-wrapper .link-wrapper:hover a {
  background-color: #00000040;
}

.schoolyear-add .progtrckr li:first-child {
  width: 100px !important;
}

.schoolyear-add .progtrckr li:nth-child(2) {
  width: 210px !important;
}

.schoolyear-add .progtrckr li:nth-child(3) {
  width: 180px !important;
}

.schoolyear-add .progtrckr li:nth-child(4), .schoolyear-add .progtrckr li:nth-child(4) span {
  width: 120px !important;
}

.archiv_view .archiv_container {
  color: #fff;
  background-color: #40b87e;
  width: 100%;
  height: 20px;
  padding-bottom: 10px;
}

.archiv_view .archiv_container .label-left {
  float: left;
}

.archiv_view .archiv_container .action-right {
  float: right;
}

.archiv_view .archiv_container .action-right a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.archiv-page .description {
  margin-bottom: 20px;
  font-size: 20px;
}

.Main {
  position: relative;
}

.Main #loading-spinner {
  background-color: #0000;
}

.Main #loading-spinner img {
  border-radius: 50%;
  width: 60px;
}

.blured-loader {
  filter: blur(3px);
}

.template-modal ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  background-color: #00000040;
  border-radius: 4px;
}

.template-page ::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  background-color: #00000040;
  border-radius: 4px;
}

.template-modal .sub-title, .template-page .sub-title {
  padding-top: 4px;
  font-weight: bold;
}

.template-modal .sub-title .small, .template-page .sub-title .small {
  font-weight: normal;
}

.template-modal.create, .template-page.create {
  padding: 25px;
}

.template-modal.overflow, .template-page.overflow {
  background-color: #f2f2f2;
  padding-right: 11px;
}

.template-modal .btn_wrapper .btn, .template-page .btn_wrapper .btn {
  margin-right: 10px;
}

.template-modal .field-group, .template-page .field-group {
  padding-top: 20px;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.template-modal .field-group.dropdowns, .template-page .field-group.dropdowns {
  clear: both;
  width: 100%;
}

.template-modal .field-group.dropdowns .col, .template-page .field-group.dropdowns .col {
  float: left;
  width: 49%;
}

.template-modal .field-group.dropdowns .col-last, .template-page .field-group.dropdowns .col-last {
  float: right;
  width: 49%;
}

.template-modal .field-group .tags-wrapper, .template-page .field-group .tags-wrapper {
  background-color: #f2f2f2;
  border-radius: 2px;
  width: 100%;
  min-height: 40px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

.template-modal .field-group .tags-wrapper .react-tagsinput, .template-page .field-group .tags-wrapper .react-tagsinput {
  background-color: #0000;
  border: none;
  padding-top: 5px;
  padding-left: 14px;
  overflow: hidden;
}

.template-modal .field-group .tags-wrapper .react-tagsinput .react-tagsinput-remove, .template-page .field-group .tags-wrapper .react-tagsinput .react-tagsinput-remove {
  color: #25292b;
  padding-left: 5px;
}

.template-modal .field-group .tags-wrapper .react-tagsinput .react-tagsinput-tag, .template-page .field-group .tags-wrapper .react-tagsinput .react-tagsinput-tag {
  color: #25292b;
  background-color: #0000001a;
  border: none;
  border-radius: 2px;
  height: 25px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px 1px;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.template-modal .field-group .footnote, .template-page .field-group .footnote {
  padding-top: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.33;
}

.template-modal .inputtext, .template-page .inputtext {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: calc(100% - 10px);
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

.template-wrapper .tags-wrapper, .template-page .tags-wrapper {
  padding-top: 20px;
}

.template-wrapper .tags-wrapper .title, .template-page .tags-wrapper .title {
  color: #3a464c;
}

.template-wrapper .tags-wrapper span, .template-page .tags-wrapper span {
  cursor: pointer;
  background-color: #0000001a;
  border-radius: 2px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 25px;
  display: inline-block;
}

.template-wrapper .tags-wrapper span.active, .template-page .tags-wrapper span.active {
  color: #fff;
  background-color: #1ca0e2;
}

.template-wrapper .tags-wrapper span.info-tag, .template-page .tags-wrapper span.info-tag {
  color: #fff;
  background-color: #3a464c;
}

.template-wrapper .tags-wrapper span.readonly, .template-page .tags-wrapper span.readonly {
  cursor: default;
}

.template-wrapper .template-list-wrapper, .template-page .template-list-wrapper {
  margin-top: 30px;
}

.template-wrapper .template-list-wrapper .item, .template-page .template-list-wrapper .item {
  background-color: #fffc;
  border-radius: 3px;
  width: 610px;
  min-height: 105px;
  margin-bottom: 14px;
  padding: 10px;
  box-shadow: 0 1px 2px #0006;
}

.template-wrapper .template-list-wrapper .item.hidden, .template-page .template-list-wrapper .item.hidden {
  display: none;
}

.template-wrapper .template-list-wrapper .item .item-wrapper, .template-page .template-list-wrapper .item .item-wrapper {
  width: 100%;
  height: auto;
}

.template-wrapper .template-list-wrapper .item .item-wrapper .left, .template-page .template-list-wrapper .item .item-wrapper .left {
  float: left;
  width: 510px;
  height: auto;
}

.template-wrapper .template-list-wrapper .item .item-wrapper .right, .template-page .template-list-wrapper .item .item-wrapper .right {
  float: left;
  width: 100px;
  height: auto;
}

.template-wrapper .template-list-wrapper .item .item-wrapper .right .action_item, .template-page .template-list-wrapper .item .item-wrapper .right .action_item {
  padding-left: 5px;
}

.template-wrapper .template-list-wrapper .item .title, .template-page .template-list-wrapper .item .title {
  font-size: 20px;
  font-weight: bold;
}

.template-wrapper .template-list-wrapper.use .item, .template-page .template-list-wrapper.use .item {
  width: 100%;
  min-height: auto;
  padding: 0;
}

.template-wrapper .template-list-wrapper.use .item .item-wrapper, .template-page .template-list-wrapper.use .item .item-wrapper {
  background-color: #1ca0e2;
  position: relative;
}

.template-wrapper .template-list-wrapper.use .item .item-wrapper .title a, .template-page .template-list-wrapper.use .item .item-wrapper .title a {
  cursor: pointer;
}

.template-wrapper .template-list-wrapper.use .item .item-wrapper .left, .template-page .template-list-wrapper.use .item .item-wrapper .left {
  float: none;
  background-color: #fff;
  width: calc(100% - 55px);
  height: auto;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  display: inline-block;
}

.template-wrapper .template-list-wrapper.use .item .item-wrapper .right, .template-page .template-list-wrapper.use .item .item-wrapper .right {
  float: none;
  cursor: pointer;
  width: 55px;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.template-wrapper .template-list-wrapper.use .item .item-wrapper .arrow, .template-page .template-list-wrapper.use .item .item-wrapper .arrow {
  color: #fff;
  font-family: arial;
  font-size: 40px;
  font-weight: 300;
  display: inline;
  position: absolute;
  top: 36%;
  right: 24px;
}

.template-wrapper .template-list-wrapper.overflow, .template-page .template-list-wrapper.overflow {
  width: calc(100% - 10px);
  margin-top: 0;
  position: relative;
  top: 8px;
  right: -3px;
}

.template-wrapper .overflow-list, .template-page .overflow-list {
  width: 100%;
  max-height: 400px;
  overflow: hidden scroll;
}

#notification {
  color: #3a464c;
  min-height: auto;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

#notification .text-wrapper {
  padding-top: 30px;
}

#notification .label {
  color: #fff;
  background-color: #40b87e;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
}

#notification .headline {
  padding-top: 10px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  display: block;
}

#notification .link-wrapper {
  padding-top: 10px;
  display: block;
}

#notification .link-wrapper a {
  letter-spacing: normal;
  color: #2a3134;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  text-decoration: underline;
}

.tab-main-wrapper .tab-link {
  cursor: pointer;
  color: #2a3134;
  background-color: #ebebeb;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 45px;
  margin-right: 5px;
  padding-left: 19px;
  padding-right: 19px;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.tab-main-wrapper .tab-link.tab-link-active {
  background-color: #fff;
}

.tab-main-wrapper .tabs-second-wrapper {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.tab-main-wrapper .tabs-second-wrapper .tab-link {
  background-color: #e3e3e3;
}

.tab-main-wrapper .tabs-second-wrapper .tab-link.tab-link-active {
  background-color: #f2f2f2;
}

.tab-main-wrapper .tabs-second-wrapper .content {
  width: 100%;
  padding: 20px;
  background-color: #f2f2f2 !important;
  border-top-left-radius: 0 !important;
}

.tab-main-wrapper .tab-content {
  background-color: #fff;
}

.tab-main-wrapper #tabpanel-notes, .tab-main-wrapper #tabpanel-notes-unit {
  padding: 0;
  font-size: 21px;
  line-height: 30px;
}

.tab-main-wrapper #tabpanel-notes .fgEditor, .tab-main-wrapper #tabpanel-notes-unit .fgEditor {
  padding-top: 70px;
  padding-bottom: 70px;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .public-DraftEditorPlaceholder-inner, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .public-DraftEditorPlaceholder-inner {
  font-size: 21px;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .__editor_wrapper, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .__editor_wrapper {
  padding-top: 20px;
  padding-left: 160px;
  padding-right: 160px;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .__editor_wrapper ul, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .__editor_wrapper ul {
  list-style: initial;
  margin-left: 22px;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .__editor_wrapper.edit_mode, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .__editor_wrapper.edit_mode {
  color: #2a3134;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .__editor_wrapper.view_mode, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .__editor_wrapper.view_mode {
  color: #6d7275;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .__editor_wrapper.view_mode .public-DraftEditorPlaceholder-inner, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .__editor_wrapper.view_mode .public-DraftEditorPlaceholder-inner {
  display: none;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .editor-control, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control {
  background-color: #fff;
  height: 59px;
  padding-left: 160px;
  padding-right: 160px;
  font-size: 14px;
  font-weight: bold;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .editor-control .left, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control .left {
  float: left;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .editor-control .right, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control .right {
  float: right;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .editor-control.sticky, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control.sticky {
  z-index: 1;
  border-bottom: 1px solid #25292b40;
  position: sticky;
  top: 0;
  left: 0;
}

.tab-main-wrapper #tabpanel-notes .fgEditor .editor-control.sticky .left, .tab-main-wrapper #tabpanel-notes .fgEditor .editor-control.sticky .right, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control.sticky .left, .tab-main-wrapper #tabpanel-notes-unit .fgEditor .editor-control.sticky .right {
  padding-top: 12px;
}

.material-upload-wrapper {
  flex-wrap: wrap;
  padding: 25px 50px 50px;
  display: flex;
}

.material-upload-wrapper .items {
  line-height: 1.5;
}

.material-upload-wrapper .items .name {
  font-size: 14px;
  font-weight: bold;
  line-height: 54px;
}

.material-upload-wrapper .items .name.link {
  vertical-align: middle;
  height: 54px;
  line-height: 23px;
}

.material-upload-wrapper .items .name.link .domain {
  color: #25292b99;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  line-height: 10px;
}

.material-upload-wrapper .items .name .domain {
  line-height: 0;
  display: block;
}

.material-upload-wrapper .items a {
  cursor: pointer;
  color: #25292b;
}

.material-upload-wrapper .icon {
  vertical-align: middle;
  width: 57px;
  position: relative;
}

.material-upload-wrapper .icon.upload {
  opacity: .1;
}

.material-upload-wrapper .icon svg {
  vertical-align: middle;
  max-height: 50px;
  display: inline-block;
}

.material-upload-wrapper .icon .favicon {
  position: absolute;
  bottom: 1px;
  right: 13px;
}

.material-upload-wrapper .icon .favicon img {
  width: 16px;
}

.material-upload-wrapper .name {
  width: 320px;
}

.material-upload-wrapper .name.upload {
  opacity: .1;
}

.material-upload-wrapper .date {
  width: 169px;
}

.material-upload-wrapper .date.upload {
  opacity: .1;
}

.material-upload-wrapper .process {
  width: 140px;
}

.material-upload-wrapper .process.upload {
  opacity: 0;
}

.material-upload-wrapper .delete {
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  display: inline-flex;
  background-color: #e3e3e3 !important;
}

.material-upload-wrapper .delete.deletedialogOpen {
  display: none;
}

.material-upload-wrapper .delete:hover {
  background-color: #cc5959 !important;
}

.material-upload-wrapper .delete img, .material-upload-wrapper .delete svg {
  width: 13px;
}

.material-upload-wrapper .add_ue_items {
  cursor: pointer;
  border-bottom: none;
  padding-bottom: 13px;
}

.material-upload-wrapper .add_ue_items a {
  color: #31383c;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
}

.material-upload-wrapper .add_ue_items a .toggle {
  color: #31383c99;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: underline;
}

.material-upload-wrapper .add_ue_items a .toggle:hover {
  color: #4bc88c;
}

.material-upload-wrapper .actions {
  width: 348px;
  padding-left: 40px;
}

.material-upload-wrapper .actions .title {
  color: #25292b;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
}

.material-upload-wrapper .actions .uploadbox {
  margin-bottom: 20px;
}

.material-upload-wrapper .actions .uploadbox .box {
  height: 140px;
  position: relative;
}

.material-upload-wrapper .actions .uploadbox .box .innerBox:hover {
  color: #4bc88c !important;
  border-color: #4bc88c !important;
}

.material-upload-wrapper .actions .uploadbox .box .innerBox p {
  cursor: pointer;
  text-align: center;
  margin: 0;
  text-decoration: underline;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#articluation_wrapper .material-upload-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}

#articluation_wrapper .material-upload-wrapper .item-list {
  width: 709px;
}

#articluation_wrapper .material-upload-wrapper .actions {
  width: 338px;
  padding-left: 25px;
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.screen_note {
  z-index: 1000;
  border-radius: 5px;
  width: 320px;
  position: fixed;
  top: 20px;
  right: 20px;
  box-shadow: 0 2px 10px #0003;
}

.screen_note.fade-in {
  opacity: 0;
  animation: 8s ease-in-out forwards fadeInOutOpacity;
}

.screen_note.fade-only-in {
  opacity: 0;
  animation: 1s ease-in-out forwards fadeInOpacity;
}

.screen_note.fade-in-error {
  opacity: 0;
  animation: 2s ease-in-out forwards fadeInOpacity;
}

.screen_note.success, .screen_note.success .content {
  background-color: #4bc88c !important;
}

.screen_note.error, .screen_note.error .content {
  background-color: #cc5959 !important;
}

.screen_note .note-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.screen_note .note-wrapper .left-controll {
  width: 54px;
}

.screen_note .note-wrapper .left-controll img {
  width: 32px;
  padding-top: 15px;
  padding-left: 13px;
}

.screen_note .note-wrapper .right-controll {
  cursor: pointer;
  background-color: #00000026;
  width: 40px;
}

.screen_note .note-wrapper .right-controll img {
  width: 14px;
  position: relative;
  top: 40%;
  left: 35%;
}

.screen_note .note-wrapper .content {
  border-radius: 0;
  width: 226px;
  padding: 0 !important;
}

.screen_note .note-wrapper .content .text {
  color: #fff;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 9px;
  font-weight: bold;
  padding-top: 11px !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.screen_note .note-wrapper .content .text a {
  color: #fff;
  text-decoration: underline;
}

.screen_note .note-wrapper .content .undo_container {
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 21px;
}

.screen_note .note-wrapper .content .undo_container a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes fadeInOutOpacity {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.save_cancel_element {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 14px;
}

.save_cancel_element .btn {
  margin-right: 20px;
}

.btn-def {
  color: #fff;
  background-color: #1ca0e2;
  border-radius: 2px;
  width: auto;
  height: 33px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 1px 3px #0000004d;
}

input {
  width: 90%;
  height: 25px;
}

.inputfield {
  background-color: #f2f2f2;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

.layout-register .field-group, #settings-dashboard .field-group {
  padding-bottom: 14px;
  padding-left: 14px;
}

.layout-register input[type="text"], .layout-register input[type="password"], #settings-dashboard input[type="text"], #settings-dashboard input[type="password"] {
  background-color: #fff;
  border: none;
  border-radius: 2px;
  height: 40px;
  padding-left: 10px;
  font-size: 14px;
  box-shadow: inset 1px 1px 1px #00000040, inset 0 0 2px #00000040;
}

.layout-register input[type="text"].grey-input, .layout-register input[type="password"].grey-input, #settings-dashboard input[type="text"].grey-input, #settings-dashboard input[type="password"].grey-input {
  background-color: #f2f2f2;
}

.checkbox {
  background-image: linear-gradient(#fff, #f7f7f7 48%, #f0f0f0 51%, #f1f1f1);
  border: 1px solid #00000059;
  border-radius: 2px;
  width: auto;
  height: auto;
  margin-right: 10px;
  box-shadow: 0 1px #00000026;
}

select {
  width: 100%;
  height: 25px;
}

.dynamicTextarea {
  color: #25292b;
  border: none;
  max-width: 500px;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
}

.form-row {
  padding-bottom: 20px;
  display: block;
}

.forms .error {
  color: red;
  padding-bottom: 15px;
  font-weight: bold;
}

.forms .field-line {
  padding-bottom: 20px;
}

.forms label {
  font-weight: bold;
  display: block;
}

.forms input {
  border: none;
  height: 35px;
  font-family: Work Sans, sans-serif;
  font-size: 14px;
}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(#8b9091, #6a6f70);
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 40px;
  padding: 8px 10px;
  transition: all .2s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 2px #00000080;
}

.Dropdown-arrow {
  content: " ";
  height: 0;
  margin-top: -ceil(2.5);
  border: 5px solid #0000;
  border-top-color: #fff;
  border-bottom-width: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 17px;
  left: 10px;
}

.Dropdown-placeholder {
  padding-left: 20px;
}

.Dropdown-menu {
  z-index: 99;
  background-color: #fff;
  width: 100%;
  height: auto;
  max-height: 300px;
  position: absolute;
  overflow-x: auto;
  box-shadow: 0 1px 3px #00000040;
}

.Dropdown-menu .Dropdown-option {
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
}

.Dropdown-menu .Dropdown-option:hover {
  background-color: #efefef;
}

#holiday-edit-form .Dropdown-control {
  color: #3a464c;
  cursor: pointer;
  width: 100%;
  height: 40px;
  box-shadow: none;
  background: #fff;
  border: 1px solid #b0b9bd;
  border-radius: 4px;
  outline: none;
  margin-top: 5px;
  padding: 8px 10px;
  transition: all .2s;
}

#holiday-edit-form .arrow-closed, #holiday-edit-form .arrow-open {
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  padding: 4px;
  display: inline-block;
  position: absolute;
  right: 20px;
}

#holiday-edit-form .arrow-closed {
  top: 12px;
  transform: rotate(45deg);
}

#holiday-edit-form .arrow-open {
  top: 16px;
  transform: rotate(-135deg);
}

#holiday-edit-form .Dropdown-placeholder {
  padding-left: 0;
}

.actions {
  float: right;
}

.actions .form-icon {
  height: 23px;
  position: relative;
  top: 6px;
}

.row {
  clear: both;
  width: 100%;
}

.row .col-50 {
  float: left;
  width: 50%;
}

.btn {
  cursor: pointer;
}

.btn.btn-dark {
  color: #fff;
  background-color: #3a464c;
  border: none;
  border-radius: 2px;
  min-width: 127px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 2px 4px #00000080;
}

.btn.btn-step {
  color: #fff;
  background-color: #4bc88c;
  border: none;
  border-radius: 2px;
  min-width: 127px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 2px 4px #00000080;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: .3;
  box-shadow: none;
  background-color: #7b8183;
  border: none;
}

a.btn {
  line-height: 40px;
  display: inline-block;
}

.layout-login .btn-dark {
  min-width: auto;
}

::-webkit-input-placeholder {
  opacity: .3;
  letter-spacing: normal;
  color: #3a464c;
  font-size: 14px;
}

::placeholder {
  opacity: .3;
  letter-spacing: normal;
  color: #3a464c;
  font-size: 14px;
}

:-ms-input-placeholder {
  opacity: .3;
  letter-spacing: normal;
  color: #3a464c;
  font-size: 14px;
}

:-moz-placeholder {
  opacity: .3;
  letter-spacing: normal;
  color: #3a464c;
  font-size: 14px;
}

.public-DraftEditorPlaceholder-inner, .css-1492t68, .css-151xaom-placeholder {
  opacity: .3;
  letter-spacing: normal;
  color: #3a464c;
  font-size: 14px;
  position: absolute;
}

.clearfix {
  clear: both;
}

.text-right {
  text-align: right;
}

.icon {
  padding-right: 15px;
}

.icon .icon-label {
  padding-right: 5px;
}

.icon.edit a {
  color: green;
}

.icon.delete a {
  color: red;
}

.infoText {
  padding-left: 14px;
  line-height: 40px;
}

textarea, input {
  font-family: Work Sans, sans-serif;
}

.footer {
  margin-top: 100px;
}

.test-layer {
  background-color: red;
  border: 1px solid;
  height: 300px;
  position: absolute;
}

.triangle_color_wrapper {
  width: 10px;
  margin-left: 20px;
  padding-top: 21px;
  position: absolute;
}

.triangle_color_wrapper .triangle_white_down {
  border: 4px solid #0000;
  border-top: 5px solid #fff;
  border-bottom-width: 0;
  width: 0;
  height: 0;
}

#appMountPoint, #content-container {
  width: 100%;
  height: 100%;
}

#appWrapper {
  height: 100%;
}

.bundle_version {
  float: left;
  color: #afafaf;
  font-size: 12px;
  display: inline-block;
}
/*# sourceMappingURL=index.647602c3.css.map */
