@use "../variables";

.note-setup-newyear-layer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  right: auto;
  left: auto;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.5);
}

.note-setup-newyear {
  width: 289px;
  height: 150px;
  background-color: #4bc88c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

  .inner-wrapper {
    padding-top: 16px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .text {
      font-family: variables.$font-family;
      font-size: 14px;
      padding-bottom: 10px;
      color: #fff;
    }
    .btn-wrapper {
      text-align: center;
      //padding-bottom: 10px;
      .link-wrapper {
        a {
          color: #fff;
          text-decoration: none;
          width: 249px;
          height: 40px;
          border-radius: 2px;
          background-color: rgba(0, 0, 0, 0.15);
          display: block;
          margin-left: auto;
          margin-right: auto;
          .linktext {
            padding-top: 10px;
            display: block;
          }
        }
        &:hover a {
          background-color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.schoolyear-add {
  .progtrckr {
    li:nth-child(1) {
      width: 100px !important;
    }
    li:nth-child(2) {
      width: 210px !important;
    }
    li:nth-child(3) {
      width: 180px !important;
    }
    li:nth-child(4) {
      width: 120px !important;
      span {
        width: 120px !important;
      }
    }
  }
}
