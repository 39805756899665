#login {
  max-width: 613px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
}
.layout-login {
  width: 100%;
  height: 100%;
  .left {
    width: 40%;
    float: left;
    background-image: url("../images/freigeist-hero-image.jpg");
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  .right {
    width: 60%;
    float: left;
    height: 100%;
    overflow: hidden;
  }

  .right-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    overflow: hidden;

    .footer {
      position: absolute;
      z-index: 20;
      width: 50%;
      bottom: 50px;
      text-align: right;
      margin-right: 50px;
      color: #3a464c;
      opacity: 0.6;

      a {
        color: #3a464c;
        opacity: 0.6;
      }
    }
  }

  .header-section {
    margin-top: 25px;
    width: 100%;
    .left-section {
      float: left;
      width: 30%;
      .text {
        position: relative;
        top: -15px;
        padding-left: 8px;
      }
    }
    .right-section {
      float: right;
      width: 70%;
      text-align: right;
      .text {
        padding-right: 15px;
      }
    }
  }

  .field-line {
    max-width: 400px;
    label {
      font-weight: normal;
    }
    input {
      padding-left: 8px;
    }
    #email,
    #password {
      width: 400px;
      height: 40px;
      border-radius: 5px;
    }
  }

  .button-line {
    .password_link {
      a {
        color: #3a464c;
        text-decoration: underline;
        margin-left: 20px;
      }
    }
  }

  .error-message {
    &.box {
      width: 413px;
      height: 104px;
      border-radius: 5px;
      background-color: #cc5959;
      color: #ffffff;
      margin-bottom: 20px;
      .text {
        padding-left: 13px;
        padding-top: 9px;
        a {
          color: #fff;
          text-decoration: underline;
        }
      }
      &.notice_logout {
        background-color: #3a464c;
        height: 128px;
      }
    }
  }

  .errorTypeWrongCredentials {
    .field-line {
      label {
        color: #cc5959;
      }
      #email,
      #password {
        border: solid 2px #cc5959;
        color: #cc5959;
      }
    }
  }
}
