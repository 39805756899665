@use "../variables";

#sequence_wrapper {
  user-select: text;

  .note-editor-wrapper {
    .__editor_wrapper {
      h1 {
        font-size: 52px;
        line-height: 58px;
        font-weight: bold;
      }
      h2 {
        font-size: 32px;
        line-height: 38px;
        font-weight: bold;
      }
      h3 {
        font-weight: bold;
      }
    }
    .fgEditor {
      min-height: 1100px;
    }
  }

  .content {
    background-color: #fff;
    padding-top: 20px;
    border-radius: 5px;
    &.no-left-top {
      border-top-left-radius: 0px;
    }
  }

  .sequence-meta-header-wrapper {
    position: relative;
  }

  #component_nav {
    .meta-wrapper {
      width: 100%;

      .pager {
        font-weight: bold;

        a {
          padding-left: 5px;
          padding-right: 5px;
          font-size: 18px;
        }
      }
    }
  }

  .details_wrapper {
    min-height: 150px;
    padding-bottom: 20px;
    display: none;
    &.show {
      display: block;
    }

    .didakt_wrapper {
      margin-top: 30px;
      width: 100%;
      .editor-wrapper {
        width: 480px;
        float: left;
        margin-right: 40px;
      }
      .tags_wrapper {
        width: 400px;
        float: left;
      }
    }

    .title {
      font-weight: bold;
      &.readonly {
        color: rgba(37, 41, 43, 0.5);
      }
    }
    .text {
      color: rgba(42, 49, 52, 0.7);
      &.css-1rtrksz {
        padding: 0;
        .css-10odw4q {
          padding-right: 6px;
        }
      }
    }
  }

  #articluation_wrapper {
    .slot-title-edit {
      &::placeholder {
        font-size: 21px;
        font-weight: bold;
      }
    }
  }

  #slot_edit {
    .duringSelectionText {
      display: inline-block;
      text-align: right;
      padding-right: 10px;
      color: red;
    }

    &.content-inner-wrapper {
      padding-top: 0px;
    }

    height: 100%;

    .delete_dayslot_content {
      padding: 15px;
    }

    .wrapper-slot-edit {
      background-color: #fff;
      width: 100%;
      min-width: 1120px;
    }

    .timeslot-header {
      display: none;
      border-bottom: 1px solid variables.$color-dark-gray;

      .slot-title {
        font-weight: bold;
        color: #25292b;

        input {
          font-size: variables.$font-size;
          font-weight: bold;
          color: #25292b;
          border: none;
        }
      }

      .flex {
        flex: 1;
        width: 50%;
      }
    }

    .flex-wrapper {
      width: 100%;
    }

    .timeline-wrapper {
      background-color: #fff;
      position: relative;
      overflow: hidden;
      float: left;
      width: 714px;
      height: 100%;
      margin-top: 5px;

      #timeline-left {
        padding-top: 15px;

        .unit-desc {
          display: inline-block;
          top: -11px;
          position: relative;
          padding-left: 12px;
          font-size: 12px;
          color: variables.$color-black;
          opacity: 0.4;
        }

        font-size: 12px;
        font-weight: 300;
        width: 30px;
        ol,
        li {
          /* removing the default list counters/markers: */
          list-style-type: none;
        }
        ol {
          /* resetting the counter so every <ol>
             has an independent count: */
          counter-reset: marker;
          margin-left: 0px;
          padding-left: 0px;
          padding-top: 0px;
          margin-top: 0px;
        }
        li {
          /* 'real world' measurements are perhaps not
             entirely faithful on screen: */
          height: 20px;
          border-top: 1px solid variables.$color-dark-gray;
          /* including the border in the height of the element: */
          box-sizing: border-box;
          width: 5px;
          /* incrementing the counter: */
          /*counter-increment: marker; */
          /* to position the counter relative
             to the <li>: */
          position: relative;
        }

        li.long {
          width: 10px;
          border-top: 1px solid variables.$color-dark-gray;
        }

        li:last-child {
          height: 0px;
        }

        /*
        li:first-child,
        li:nth-child(5n) {

          width: 15px;
        }

        li:last-child {
          height: 0;
        }
        li:first-child::after,
        li:nth-child(5n)::after {

          position: absolute;

          top: -0.5em;

          left: 100%;
          height: 1em;
          line-height: 1em;
          width: 2em;
          text-align: center;

          content: counter(marker);
        }
        li:after {
          counter-increment: marker;
        }
        */
      }

      #timeline-items {
        position: absolute;
        width: 670px;
        height: 100%;
        top: 15px;
        left: 30px;

        #timeslot-item-wrapper,
        .timeslot-item-wrapper {
          position: absolute !important;
          width: 100%;
          height: auto !important;
          top: 0px;
          z-index: 1;

          .pane-item {
            box-sizing: border-box;
            padding: 2px 0;

            &.no_move {
              transform: none !important;
              position: relative !important;
            }

            &.drag_active {
              .mover {
                background-color: #1ca0e2 !important;
              }
            }
          }

          .timeline-slot {
            background-color: variables.$main-grey;
            height: 100%;
            width: 100%;
            box-sizing: margin-box;

            .header-edit {
              position: absolute;
              z-index: 90;
              right: 8px;
              font-size: 10px;
              color: #25292b;
              top: 5px;

              a {
                color: #25292b;
                &:hover {
                  color: #cc5959;
                }
              }
            }

            .slot-wrapper {
              width: 100%;
              height: 100%;
              overflow: hidden;

              .editor-wrapper {
                position: relative;
                width: 200px;
                right: 0;
                z-index: auto;
                top: 10px;
                float: right;
                height: 100%;

                .material-edit {
                  width: 100%;
                }
                &.close {
                  overflow-x: auto;
                }
                &.on {
                  position: absolute;
                }
              }

              .mover {
                user-select: none;
                cursor: move;
                float: left;
                width: 25px;
                height: 100%;
                text-align: center;
                background-color: #e3e3e3;
                background-image: url("../images/dragger.png");
                background-repeat: no-repeat;
                background-size: 10px 5px;
                background-position: 8px 17px;

                &:hover {
                  background-color: #1ca0e2;
                }

                .dragger {
                  text-align: center;
                  width: 10px;
                  display: none;
                }
              }
              .content-edit {
                border: 1px;
                width: 423px;
                float: left;
                padding-left: 10px;
                height: 100%;

                .header {
                  padding-top: 7px;
                }

                .text {
                  height: calc(100% - 35px);
                  overflow: auto;
                  margin-bottom: 10px;
                }
              }
            }

            .meta {
              width: 100%;
              padding-top: 10px;
              height: 10%;

              .edit {
                padding-left: 10px;
                padding-right: 10px;
                float: left;
                width: 70%;
              }
              .view {
                padding-left: 10px;
                padding-right: 5px;
                float: left;
                width: 22%;
                text-align: right;
                font-size: variables.$font-size;

                .duration {
                  padding-right: 15px;
                }

                .dragger {
                  cursor: move;
                }
              }
            }

            .content {
              padding-left: 10px;
              padding-right: 5px;
              height: 88%;
              overflow-x: auto;
              width: 97%;

              textarea {
                resize: none;
                width: 99%;
                border: none;
                background-color: transparent;
                font-size: variables.$font-size;
                height: 60%;
              }
            }
          }
        }

        .item {
          &.selecting {
            background-color: variables.$main-grey;
            border-top: none !important;
          }
        }

        .time-row {
          font-size: 12px;
          border-top: 1px solid variables.$color-dark-gray;
          height: 99px;

          .label {
            text-align: right;
          }
        }
      }
    }

    .attachments-wrapper {
      width: 406px;
      //flex: 1;
      float: left;

      .section {
        &.didakt-hinter {
          .public-DraftEditorPlaceholder-inner {
            max-width: 300px;
          }
        }

        padding-left: 20px;
        &:first-child {
          padding-top: 15px;
        }

        margin-bottom: 30px;
        .title {
          font-weight: bold;
          &.readonly {
            color: rgba(37, 41, 43, 0.5);
          }
        }
        .text {
          color: rgba(42, 49, 52, 0.7);
          &.css-1rtrksz {
            padding: 0;
            .css-10odw4q {
              padding-right: 6px;
            }
          }
        }
      }
    }
  }
}
