.pdf_download {
  margin-top: 25px;
  font-weight: bold;
  a {
    color: #2a3134 !important;
    text-decoration: underline;
  }
  .icon-wrapper {
    .icon {
      padding-right: 8px;
    }
  }
  .text-wrapper {
    position: relative;
    top: -10px;
  }
}
