.archiv_view {
  //border: 10px solid #40b87e;
  //height: calc(100% - 20px);

  .archiv_container {
    background-color: #40b87e;
    height: 20px;
    color: #fff;
    width: 100%;
    padding-bottom: 10px;

    .label-left {
      float: left;
    }

    .action-right {
      float: right;
      a {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.archiv-page {
  .headline {
  }
  .description {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
