@use "sass:meta";
@use "variables";

html {
  width: 100%;
  height: 100%;
}

body {
  padding: 0px;
  margin: 0px;
  background-color: variables.$main-grey;
  font-family: variables.$font-family !important;
  user-select: none;
  font-size: variables.$font-size;
  line-height: variables.$line-height;
  overflow-x: auto;
  width: 100%;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  min-height: 100%;
  box-sizing: border-box;

  &.fullheight {
    height: 100%;
  }

  //border: 10px solid #40b87e;

  &.archiv_view {
    border: 10px solid #40b87e;
  }
}

//* { box-sizing: border-box; }

a,
a:focus,
a:hover {
  color: variables.$color-water-blue;
  text-decoration: none;
}
a.anchor {
  cursor: pointer;
}

h1 {
  font-size: 21px;
  font-weight: bold;
  margin: 0;
  padding: 0;

  &.home {
    font-size: 28px;
    color: #4bc88c;
  }
}

#loading-spinner {
  display: none;
  position: absolute;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;

  &.running {
    display: block;
  }

  .logo-container {
    position: relative;
    top: 35%;
    width: 100%;
    text-align: center;

    .text {
      margin-top: 20px;
      font-weight: bold;
    }

    img {
      width: 125px;
      border-radius: 50%;
    }
  }
}

.wrapper,
.content-inner-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: variables.$spacer;
  padding-bottom: variables.$spacer;

  @media all and (max-width: 1350px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &.header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content-wrapper {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;

  .inner-wrapper {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* helpers */
.background-white {
  background-color: #fff;
}
.text-right {
  text-align: right;
}

/* ui components - react components */
@include meta.load-css("module/navigation");
@include meta.load-css("module/login");
@include meta.load-css("module/calendar");
@include meta.load-css("module/ab_weeks");

@include meta.load-css("module/sequences");
@include meta.load-css("module/setttings");
@include meta.load-css("module/stepzila");
@include meta.load-css("module/password");
@include meta.load-css("module/registration");
@include meta.load-css("module/subjects");
@include meta.load-css("module/classrooms");
@include meta.load-css("module/timetable");
@include meta.load-css("module/school");
@include meta.load-css("module/dashboard/dashboard");
@include meta.load-css("module/messages");
@include meta.load-css("module/editor");
@include meta.load-css("module/select");
@include meta.load-css("module/pdf");
@include meta.load-css("module/modal");
@include meta.load-css("module/schoolyears");
@include meta.load-css("module/archiv");
@include meta.load-css("module/loader");
@include meta.load-css("module/template");
@include meta.load-css("module/notifications");
@include meta.load-css("module/tabs");
@include meta.load-css("module/material");
@include meta.load-css("module/animations");
@include meta.load-css("module/screen-note");

/* html elements */
@include meta.load-css("elements/form");
@include meta.load-css("elements/grid");
@include meta.load-css("elements/buttons");

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.3;
  font-size: 14px;
  //line-height: inherit;
  letter-spacing: normal;
  color: #3a464c;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.3;
  font-size: 14px;
  //line-height: inherit;
  letter-spacing: normal;
  color: #3a464c;
}
:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.3;
  font-size: 14px;
  //line-height: inherit;
  letter-spacing: normal;
  color: #3a464c;
}
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.3;
  font-size: 14px;
  //line-height: inherit;
  letter-spacing: normal;
  color: #3a464c;
}

.public-DraftEditorPlaceholder-inner,
.css-1492t68,
.css-151xaom-placeholder {
  opacity: 0.3;
  font-size: 14px;
  letter-spacing: normal;
  color: #3a464c;
  position: absolute;
}

.clearfix {
  clear: both;
}

.text-right {
  text-align: right;
}

.icon {
  padding-right: 15px;
  .icon-label {
    padding-right: 5px;
  }

  &.edit {
    a {
      color: green;
    }
  }

  &.delete {
    a {
      color: red;
    }
  }
}

.infoText {
  padding-left: 14px;
  line-height: 40px;
}

textarea,
input {
  font-family: variables.$font-family;
}

.footer {
  margin-top: 100px;
}

.test-layer {
  position: absolute;
  height: 300px;
  background-color: red;
  border: 1px solid;
}

.triangle_color_wrapper {
  position: absolute;
  width: 10px;
  padding-top: 21px;
  margin-left: 20px;
  .triangle_white_down {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent;
  }
}

#appMountPoint,
#content-container {
  width: 100%;
  height: 100%;
}

#appWrapper {
  height: 100%;
}

.bundle_version {
  display: inline-block;
  float: left;
  color: #afafaf;
  font-size: 12px;
}
