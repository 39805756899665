.message-box {
  line-height: 21px;
  &.notice {
    background-color: #cc5959;
    color: #fff;
    padding: 15px;
    margin-bottom: 15px;

    .btn-dark {
      width: auto;
      font-weight: 700;
    }
  }

  &.message {
    color: #fff;
    padding: 15px;
    margin-bottom: 15px;
  }
}
